<div class="form-row">
    <div class="form-group col mb-0">
        <div class="query-builder form-control"
            [ngClass]="{'form-control-sm' : size === 'sm', 'form-control-lg': size === 'lg'}" [attr.disabled]="disabled">
            <div ngbDropdown class="query-builder-history-wrapper" [autoClose]="true">
                <button class="btn btn-light text-dark border-0 border-right query-builder-history-button"
                    [ngClass]="{'btn-sm' : size === 'sm', 'btn-lg': size === 'lg'}" id="dropdownBasic2" ngbDropdownToggle>
                    <fa-icon [icon]="['far', 'history']" [size]="size"></fa-icon>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="query-builder-dropdown"
                    style="min-width: 400px; border-top-left-radius: 0px; border-top-right-radius: 0px;">
                    <h5 style="padding: 0 1.25rem;">Your recent searches</h5>
                    <ul class="list-group list-group-small">
                        <li ngbDropdownItem class="list-group-item list-group-item-action"
                            *ngFor="let search of recentSearches | async"
                            (click)="setQueryFromRecentSearch(search, true )">
                            <div class="small text-muted">{{ search.created_at | amLocal | amTimeAgo }}</div>
                            <div *ngFor="let k of search.query | objectToKVP">
                                {{ k.key | titlecase }}: {{ k.value.label }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="token-container-wrapper">
                <ul class="token-container">
                    <li class="token query-token" *ngFor="let token of queryTokens; index as idx">
                        <div class="query-token-inner d-flex" (click)="onEditToken(idx, token)">
                            <div class="query-token-label border-right" *ngIf="!token.attribute.fulltext">
                                {{ token.attribute.label }}</div>
                            <div class="query-token-value" *ngIf="token.value">
                                {{ token.value.label }}
                                <span class="query-token-remove-wrapper" (click)="removeQueryToken(idx)"
                                    *ngIf="!disabled">
                                    <fa-icon [icon]="['far', 'times']" class="ml-1"></fa-icon>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li class="token input-token" ngbDropdown #controlDropDown="ngbDropdown" container="body"
                        [autoClose]="editingIdx !== null ? 'inside' : true">
                        <input #controlInput autocomplete="off" class="form-control" ngbDropdownAnchor
                            [disabled]="disabled" (click)="controlDropDown.open()" [(ngModel)]="controlInputValue"
                            [ngClass]="{'form-control-sm' : size === 'sm', 'form-control-lg': size === 'lg'}"
                            (keydown.backspace)="onInputBackspace($event)" (keydown.enter)="onInputEnter($event)"
                            [attr.placeholder]="queryTokens.length === 0 ? 'Search or filter results...': ''"
                            (input)="controlInputChanged($event)" />
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="shadow-sm query-builder-dropdown">
                            <ng-container *ngIf="editingIdx === null">

                                <button ngbDropdownItem (click)="onInputEnter($event)">
                                    <fa-icon [icon]="['far', 'search']" [fixedWidth]="true" class="mr-1">
                                    </fa-icon>Enter or click to
                                    search
                                </button>
                                <ng-container *ngFor="let token of tokens">
                                    <button ngbDropdownItem
                                        *ngIf="!controlInputValue || token.label.toLowerCase().startsWith(controlInputValue.toLowerCase())"
                                        (click)="addQueryToken(token)">
                                        <fa-icon [icon]="token.icon" [fixedWidth]="true" class="mr-1"></fa-icon>
                                        {{ token.label }}
                                    </button>
                                </ng-container>
                                <button ngbDropdownItem (click)="addFTXToken(controlInputValue)"
                                    *ngIf="controlInputValue && fullTextSearch">
                                    Search: "{{ controlInputValue }}"
                                </button>
                            </ng-container>
                            <ng-container *ngIf="editingIdx !== null">
                                <ng-container *ngIf="suggestions && suggestions.length > 0; else defaultSuggestions">
                                    <button ngbDropdownItem *ngFor="let suggestion of suggestions"
                                        (click)="setTokenValue(suggestion)">
                                        {{ suggestion.label }}
                                    </button>
                                </ng-container>

                            </ng-container>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>

    <ng-container *ngIf="sortOptions && sortOptions.length > 0">
        <div class="form-group col-auto mb-0">
            <div class="input-group input-group-sm">
                <select class="custom-select" [(ngModel)]="selectedSortOption">
                    <option *ngFor="let opt of sortOptions" [ngValue]="opt">{{ opt.label }}</option>
                </select>
                <div class="input-group-append">
                    <button class="btn border bg-white" [disabled]="!selectedSortOption?.allowChangeDirection"
                        [ngClass]="{'bg-light text-muted': !selectedSortOption?.allowChangeDirection}"
                        (click)="flipSortDirection(selectedSortOption)">
                        <fa-icon
                            [icon]="['fas', selectedSortOption?.direction === 'asc' ? 'sort-amount-down' : 'sort-amount-up']">
                        </fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #defaultSuggestions>
    <div class="px-2">
        <span *ngIf="!controlInputValue && !loadingSuggestions">Type something to start searching...</span>
        <span *ngIf="controlInputValue && !loadingSuggestions">No suggestions found...</span>
        <span *ngIf="controlInputValue && loadingSuggestions">Searching...</span>
    </div>
</ng-template>
