import { Pipe, PipeTransform } from '@angular/core';
import { convertDecimalToDMS } from './../../../helpers/mapHelpers';

@Pipe({
    name: 'decimalToDMS',
})
export class DecimalToDMSPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: { lat: number; lng: number }, ...args: any[]): any {
        return convertDecimalToDMS(value);
    }
}
