import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ShipmentsService } from './../../../services/shipments.service';

@Component({
    selector: 'qw-attach-document',
    templateUrl: './attach-shipment-document.component.html',
    styleUrls: ['./attach-shipment-document.component.scss'],
})
/**
 * @deprecated
 */
export class AttachShipmentDocumentComponent {
    public form: FormGroup;
    /**
     * The component's busy state.
     */
    public busy = false;

    /**
     * The component's error state.
     */
    public error = false;

    /**
     * Max file size bytes of attached document
     */
    public maxFileSizeBytes = 2097152;

    constructor(
        public modal: NgbActiveModal,
        private fb: FormBuilder,
        private service: ShipmentsService
    ) {
        // Setup the form for this component.
        this.form = this.fb.group({
            shipmentId: [null, [Validators.required]],
            file: [null, [Validators.required]],
            description: [null, [Validators.maxLength(191)]],
            innerFile: [null, [Validators.required]],
            fileSize: [0, [Validators.max(this.maxFileSizeBytes)]],
        });
    }

    /**
     * Handles attaching a newly selected file to the form.
     * @param event The event generated by the file browser.
     * @returns void
     */
    public onFileSelected(event): void {
        if (this.busy) {
            // already working, abort.
            return;
        }
        const file =
            event.target.files.length > 0 ? event.target.files[0] : null;

        if (!file) {
            // No file, abort.
            return;
        }
        this.form.get('fileSize')?.setValue(file.size);
        this.form.get('innerFile')?.setValue(file);
    }

    /**
     * Publically accessible method for setting the Shipment ID necessary to attach the document.
     * @param shipmentId The shipment ID the document should be attached to.
     */
    public setShipmentId(shipmentId: string): void {
        this.form.get('shipmentId')?.setValue(shipmentId);
    }

    /**
     * Uploads attach document to the server
     * @param _event The event that happened on submit
     * @returns void
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    public upload(_event: any): void {
        // Validation.
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        // Set form and component state.
        this.busy = true;
        this.form.disable();
        this.error = false;

        // Get and parse the payload.
        const formValue = this.form.value;
        const payload = {
            file: formValue.innerFile,
            description: formValue.description,
        };

        // Send the payload to the server.
        const uploadSubscription = this.service
            .uploadShipmentAttachment(formValue.shipmentId, payload)
            .subscribe(
                result => {
                    uploadSubscription.unsubscribe();
                    this.busy = false;
                    this.form.enable();
                    this.modal.close(result);
                },
                () => {
                    uploadSubscription.unsubscribe();
                    this.busy = false;
                    this.error = true;
                    this.form.enable();
                }
            );
    }

    /**
     * Returns a context sensitive string for the file browser label.
     */
    public get fileBrowserLabel(): string {
        const file = this.form.get('innerFile')?.value;
        if (!file) {
            return 'Choose a file';
        }

        return `${file.name} (~${Math.ceil(file.size * 0.0009765625)} kb)`;
    }
}
