import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@qwyk/portals/environment';

import { Observable, of } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';

import { Country, ContainerType } from '../domain/masterdata.models';

@Injectable({
    providedIn: 'root',
})
export class MasterDataService {
    private cache: {
        countries: Country[];
        containerTypes: ContainerType[];
    } = { countries: [], containerTypes: [] };

    constructor(private http: HttpClient) {}

    public getCountries(): Observable<Country[]> {
        if (this.cache.countries?.length) {
            return of(this.cache.countries);
        }

        return this.http
            .get<{ data: Country[] }>(
                `${environment.backendServer}/api/masters/countries`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.cache.countries = c))
            );
    }

    public getContainerTypes(): Observable<ContainerType[]> {
        if (this.cache.containerTypes?.length) {
            return of(this.cache.containerTypes);
        }

        return this.http
            .get<{ data: ContainerType[] }>(
                `${environment.backendServer}/api/masters/container_types`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.cache.containerTypes = c))
            );
    }
}
