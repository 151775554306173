import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCaseToSentence',
})
export class TitleCaseToSentencePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        const segments = value.match(/[A-Z][a-z]+/g);
        if (!segments) {
            return value;
        }
        return segments.join(' ');
    }
}
