<div class="d-flex row">
    <div class="col-4 border-right pr-0">
        <a ngbDropdownItem [routerLink]="['/my-portal', 'shipments', 'list']">Your
            shipments</a>
        <a ngbDropdownItem [routerLink]="['/my-portal', 'shipments', 'list']"
            [queryParams]="{filter: ['starred',true]}">Starred</a>
        <a ngbDropdownItem [routerLink]="['/my-portal', 'shipments', 'list']"
            [queryParams]="{query_key: 'milestone', query_value: 'origin.booked', query_display: 'Booked'}">Booked</a>
        <a ngbDropdownItem [routerLink]="['/my-portal', 'shipments', 'list']"
            [queryParams]="{query_key: 'milestone', query_value: 'DRAFT', query_display: 'Draft'}">Drafts</a>
    </div>
    <div class="col-8">
        <div class="py-1 pr-3">
            <!-- <form class="form mb-2"> -->
            <input type="text" [(ngModel)]="searchInput" class="form-control form-control-sm"
                placeholder="Search shipments..." (keydown.enter)="onSearchKeyEnter($event)">
            <!-- </form> -->
            <h6 class="text-muted mb-0 mt-3">Recent shipments</h6>
            <ul class="list-group list-group-flush">
                <li ngbDropdownItem class="list-group-item list-group-item-action overflow-hidden px-0"
                    style="cursor: pointer;" *ngFor="let shipment of shipments$ | async"
                    [routerLink]="shipment.sys_state === 'DRAFT' || shipment.sys_state === 'SENDING_FAILED' ? ['/my-portal/booking/new/', shipment.id] : ['/my-portal/shipments/', shipment.id]">
                    <div>{{ shipment.name }}</div>
                </li>
            </ul>
        </div>
    </div>
</div>
