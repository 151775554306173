import { DashboardService } from './../../services/dashboard.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';

@Component({
    selector: 'qw-widget-selector',
    templateUrl: './widget-selector.component.html',
    styleUrls: ['./widget-selector.component.scss'],
})
export class WidgetSelectorComponent {
    public datasets$ = this.service.getDataSets();
    public selectedSet = null;
    public selectedSetIdx: null | number = null;
    public selectedWidget = null;
    public selectedWidgetIdx: null | number = null;
    constructor(
        private modal: NgbActiveModal,
        private service: DashboardService
    ) {}

    public selectSet(idx: number, set) {
        if (idx === this.selectedSetIdx) {
            return;
        }
        this.selectedSet = set;
        this.selectedSetIdx = idx;
        this.selectedWidget = null;
        this.selectedWidgetIdx = null;
    }

    public selectWidget(idx: number, widget) {
        if (idx === this.selectedWidgetIdx) {
            return;
        }
        this.selectedWidget = widget;
        this.selectedWidgetIdx = idx;
    }

    public save() {
        this.modal.close({
            caption: 'Test',
            dataset: this.selectedSet,
            widget: this.selectedWidget,
        });
    }
}
