import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';

@Pipe({
    name: 'portIcon',
})
export class PortIconPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, side: 'pol' | 'pod'): any {
        // eslint-disable-next-line no-prototype-builtins
        if (Constants.TRANSPORT_MODE_ICONS[side].hasOwnProperty(value)) {
            return Constants.TRANSPORT_MODE_ICONS[side][value];
        }
        return 'question';
    }
}
