{{ widgetConfig.caption }}
<ul class="list-group">
    <div *ngIf="data$ | async; let data">
        <li *ngFor="let row of data.data" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
                <h6>{{ row[data.mapping.header] }}</h6>
                <span class="text-muted">{{ row[data.mapping.date] | amTimeAgo }}</span>
            </div>
        </li>
    </div>
</ul>