<div class="container-fluid pt-3">
    <div class="container">
        <div class="card bg-light shadow-sm">
            <div class="card-body">
                <form>
                    <div class="form-group">
                        <label class="lead">Tell us what you'd like to do</label>
                        <input type="text" class="form-control form-control-lg"
                            placeholder="E.g. book 3 pallets from Rotterdam to Singapore..." />
                        <small id="emailHelp" class="form-text text-muted text-right">AI powered by <a
                                href="#">RPALabs</a></small>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
