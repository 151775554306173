import { MasterDataService } from '../../../../services/master-data.service';
import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { Country } from '../../../../domain/masterdata.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'qw-party-address',
    templateUrl: './party-address.component.html',
    styleUrls: ['./party-address.component.scss'],
})
export class PartyAddressComponent {
    @Input() formGroup: FormGroup;
    @Input() size: 'sm' | null | 'lg' = null;
    @Input() labelsRight = true;
    @Input() formClass = 'my-3';
    @Input() readonly = false;

    countries$: Observable<Country[]> = this.masterData.getCountries().pipe(
        map(c => {
            return c.sort((a, b) => {
                return a.short_name > b.short_name ? 1 : -1;
            });
        })
    );

    constructor(private masterData: MasterDataService) {}
}
