import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'qw-dimensions-dialog',
    templateUrl: './dimensions-dialog.component.html',
    styleUrls: ['./dimensions-dialog.component.scss'],
})
export class DimensionsDialogComponent implements OnInit {
    public dimensionsForm: FormGroup;

    constructor(private fb: FormBuilder, private modal: NgbActiveModal) {
        this.dimensionsForm = this.fb.group({
            lines: this.fb.array([this.createDimensionsLine()]),
            volume: { value: 0, disabled: true },
            weight: { value: 0, disabled: true },
            unit: 'cbm',
        });
    }

    ngOnInit() {
        this.dimensionsForm.valueChanges.subscribe(value => {
            this.calculateAndSetTotals(value);
        });
    }

    private calculateAndSetTotals(formValue) {
        let totalVolume = 0;
        let totalWeight = 0;
        for (let i = 0; i < formValue.lines.length; i++) {
            const line = formValue.lines[i];
            let lineTotal =
                (line.width || 0) *
                (line.height || 0) *
                (line.length || 0) *
                (line.quantity || 0);

            lineTotal =
                Math.round(
                    (lineTotal / (formValue.unit === 'cbm' ? 1e6 : 1728)) * 1000
                ) / 1000;

            this.dimensionsForm
                .get(`lines.${i}.line_total`)
                ?.setValue(lineTotal, { emitEvent: false });
            totalVolume += lineTotal;
            totalWeight += line.weight * line.quantity;
        }
        this.dimensionsForm
            .get('volume')
            ?.setValue(Math.round(totalVolume * 1000) / 1000, {
                emitEvent: false,
            });
        this.dimensionsForm
            .get('weight')
            ?.setValue(Math.round(totalWeight * 100) / 100, {
                emitEvent: false,
            });
    }

    public setDimensions(dimensions, unit: 'cbm' | 'cft') {
        if (dimensions && dimensions.length > 0) {
            const linesFormArray = this.dimensionsForm.get(
                'lines'
            ) as FormArray;
            linesFormArray.clear();
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < dimensions.length; i++) {
                const dimensionsLine = dimensions[i];
                const dimensionsFormGroup = this.fb.group({
                    quantity: [
                        dimensionsLine.quantity,
                        [Validators.required, Validators.min(1)],
                    ],
                    length: [
                        dimensionsLine.length,
                        [Validators.required, Validators.min(0.001)],
                    ],
                    width: [
                        dimensionsLine.width,
                        [Validators.required, Validators.min(0.001)],
                    ],
                    height: [
                        dimensionsLine.height,
                        [Validators.required, Validators.min(0.001)],
                    ],
                    weight: [
                        dimensionsLine.weight,
                        [Validators.required, Validators.min(0.01)],
                    ],
                    line_total: {
                        value: dimensionsLine.total_volume.value,
                        disabled: true,
                    },
                });
                linesFormArray.push(dimensionsFormGroup);
            }

            this.dimensionsForm.get('unit')?.setValue(unit);

            this.calculateAndSetTotals(this.dimensionsForm.value);
        }
    }

    public addLine() {
        (this.dimensionsForm.get('lines') as FormArray).push(
            this.createDimensionsLine()
        );
    }

    public removeLine(idx: number) {
        (this.dimensionsForm.get('lines') as FormArray).removeAt(idx);
    }

    public createDimensionsLine() {
        return this.fb.group({
            quantity: [null, [Validators.required, Validators.min(1)]],
            length: [null, [Validators.required, Validators.min(0.001)]],
            width: [null, [Validators.required, Validators.min(0.001)]],
            height: [null, [Validators.required, Validators.min(0.001)]],
            weight: [null, [Validators.required, Validators.min(0.01)]],
            line_total: { value: 0, disabled: true },
        });
    }

    public apply() {
        for (const control in this.dimensionsForm.controls) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.dimensionsForm.controls.hasOwnProperty(control)) {
                this.dimensionsForm.controls[control].markAsTouched();
                if (control === 'lines') {
                    const formArray = this.dimensionsForm.get(
                        control
                    ) as FormArray;

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < formArray.length; i++) {
                        const group = formArray.get(i.toString()) as FormGroup;

                        // tslint:disable-next-line: forin
                        for (const ctrl in group.controls) {
                            group.controls[ctrl].markAsTouched();
                        }
                    }
                }
            }
        }

        if (this.dimensionsForm.invalid) {
            return;
        }
        const formValue = this.dimensionsForm.value;

        const unit = formValue.unit;

        let totalVolume = 0;
        let totalWeight = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < formValue.lines.length; i++) {
            const line = formValue.lines[i];
            let lineTotal =
                (line.width || 0) *
                (line.height || 0) *
                // tslint:disable-next-line: no-string-literal
                (line['length'] || 0) *
                (line.quantity || 0);

            lineTotal =
                Math.round((lineTotal / (unit === 'cbm' ? 1e6 : 1728)) * 1000) /
                1000;

            line.total_volume = {
                value: lineTotal,
                unit,
            };

            line.total_weight = {
                value: line.weight * line.quantity,
                unit: unit === 'cbm' ? 'kgs' : 'lbs',
            };

            totalVolume += lineTotal;
            totalWeight += line.weight * line.quantity;
        }

        const output = {
            weight: {
                value: totalWeight,
                unit: unit === 'cbm' ? 'kgs' : 'lbs',
            },
            volume: {
                value: totalVolume,
                unit,
            },
            dimensions: formValue.lines,
        };

        this.modal.close(output);
    }

    public get linesFormArray(): FormArray {
        return this.dimensionsForm.get('lines') as FormArray;
    }
}
