<div id="controlButton" #controlButton class="lta-button"
    [ngClass]="{'form-control': !readonly, 'form-control-plaintext': readonly, 'form-control-lg': size === 'lg', 'is-empty': !selectedItem, 'is-invalid': invalid, 'bg-light': disabled && !readonly}"
    [title]="selectedItemLabel || placeholder" (click)="openSuggestionsPanel()">
    <ng-container *ngIf="!readonly">
        <fa-icon [icon]="['far', 'map-pin']" [fixedWidth]="true"></fa-icon> {{ selectedItemLabel || placeholder }}
    </ng-container>
    <ng-container *ngIf="readonly && selectedItemLabel">
        {{ selectedItemLabel }}
    </ng-container>
    <span class="placeholder-text" *ngIf="readonly && !selectedItemLabel">
        {{ placeholder }}
    </span>
</div>
