import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectToDate',
})
export class ObjectToDatePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): Date {
        if (value) {
            return new Date(
                value.Year || value.year,
                (value.Month || value.month) - 1,
                value.Day || value.day
            );
        }
        return value;
    }
}
