/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { ShipmentTrackingEventsFacade } from '@qwyk/shared-stores/shipment-tracking-events';

@Component({
    selector: 'qwyk-shipment-tracking-events-view',
    templateUrl: './shipment-tracking-events-view.component.html',
    styleUrls: ['./shipment-tracking-events-view.component.scss'],
})
export class ShipmentTrackingEventsViewComponent {
    public shipmentTrackingEvents$ =
        this.shipmentTrackingEvents.allShipmentTrackingEvents$;
    public loading$ = this.shipmentTrackingEvents.loading$;

    constructor(private shipmentTrackingEvents: ShipmentTrackingEventsFacade) {}

    private _shipment: any;
    public get shipment(): any {
        return this._shipment;
    }

    @Input()
    public set shipment(shipment: any) {
        this._shipment = shipment;
        this.shipmentTrackingEvents.init(shipment);
    }
}
