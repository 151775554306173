<form *ngIf="formGroup" [formGroup]="formGroup" [ngClass]="formClass">
    <div class="form-group row" [ngClass]="{'mb-1': size === 'sm'}">
        <label class="col-form-label  font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Name</label>
        <div [ngClass]="{'col-10': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="name"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('name').invalid && formGroup.get('name').touched}"
                placeholder="Name" />
        </div>
    </div>
    <div class="form-group row" [ngClass]="{'mb-1': size === 'sm'}">
        <label class=" col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Address</label>
        <div [ngClass]="{'col-10': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="address_1"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('address_1').invalid && formGroup.get('address_1').touched}"
                style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;" placeholder="Address" />
            <input type="text" formControlName="address_2"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('address_2').invalid && formGroup.get('address_2').touched}"
                style="border-top-left-radius: 0; border-top-right-radius: 0; border-top-width: 0;"
                placeholder="Address" />
        </div>
    </div>
    <div class="form-group row" [ngClass]="{'mb-1': size === 'sm'}">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">
            Postal / Zip {{ size !== 'sm' ? 'Code' : null}}
        </label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="postal_code"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('postal_code').invalid && formGroup.get('postal_code').touched}"
                placeholder="Postal / Zip Code" />
        </div>
        <label class="col-form-label  font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3 mt-1': size === 'sm', 'text-right': labelsRight}">City</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9 mt-1': size === 'sm'}">
            <input type="text" formControlName="city"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('city').invalid && formGroup.get('city').touched}"
                placeholder="City" />
        </div>

    </div>
    <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">State</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="state"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('state').invalid && formGroup.get('state').touched}"
                placeholder="State" />
        </div>
    </div>
    <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Country</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <ng-select [items]="countries$ | async" bindValue="alpha_2_code" bindLabel="short_name" appendTo="body"
                placeholder="Country" formControlName="country_code" [readonly]="readonly"
                [ngClass]="{'readonly': readonly, 'is-invalid': formGroup.get('country_code').invalid && formGroup.get('country_code').touched}">
                <ng-template ng-label-tmp let-item="item">
                    {{ item.short_name }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <span
                        class="text-muted border-right pr-1 mr-1 text-monospace">{{item.alpha_2_code}}</span>{{item.short_name}}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <!-- <input type="text" formControlName="country_code" /> -->
    <hr *ngIf="size !== 'sm'">
    <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}" *ngIf="formGroup.contains('email')">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Contact</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="contact_name"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('contact_name').invalid && formGroup.get('contact_name').touched}"
                placeholder="Contact Name" />
        </div>
    </div>
    <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Phone</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="phone_number"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('phone_number').invalid && formGroup.get('phone_number').touched}"
                placeholder="Phone Number" />
        </div>
    </div>
    <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}" *ngIf="formGroup.contains('email')">
        <label class="col-form-label font-weight-bold"
            [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Email</label>
        <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
            <input type="text" formControlName="email_address"
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('email_address').invalid && formGroup.get('email_address').touched}"
                placeholder="Email Address" />
        </div>
    </div>
    <ng-container *ngIf="['pickup', 'delivery'].includes(formGroup.get('address_type').value)">
        <hr>
        <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}">
            <label class="col-form-label font-weight-bold"
                [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Reference</label>
            <div [ngClass]="{'col-4': !size || size==='lg', 'col-9': size === 'sm'}">
                <input type="text" formControlName="reference"
                    [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('reference').invalid && formGroup.get('reference').touched}"
                    placeholder="Reference">
            </div>
        </div>
        <div class="row form-group" [ngClass]="{'mb-1': size === 'sm'}">
            <label class="col-form-label font-weight-bold"
                [ngClass]="{'col-2': !size || size==='lg', 'col-3': size === 'sm', 'text-right': labelsRight}">Remarks
                and instructions</label>
            <div [ngClass]="{'col-8': !size || size==='lg', 'col-9': size === 'sm'}">
                <textarea rows="3" formControlName="remarks"
                    [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly, 'is-invalid': formGroup.get('remarks').invalid && formGroup.get('remarks').touched}"
                    placeholder="Remarks and instructions"></textarea>
            </div>
        </div>
    </ng-container>
</form>
