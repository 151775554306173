import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@qwyk/portals/environment';

@Injectable({
    providedIn: 'root',
})
export class SiteWideSearchService {
    constructor(private http: HttpClient) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public search(query): Observable<{ hits: any[] }> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<{ hits: any[] }>(
            `${environment.backendServer}/api/portals/shipments/search?query=${query}`
        );
    }
}
