import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ShipmentsService } from './../../../services/shipments.service';

@Component({
    selector: 'qw-shipments-navbar-widget',
    templateUrl: './shipments-navbar-widget.component.html',
    styleUrls: ['./shipments-navbar-widget.component.scss'],
})
export class ShipmentsNavbarWidgetComponent {
    @Input()
    public parentDropdown: NgbDropdown;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shipments$: Observable<any[]> = this.service.getShipments(
        null,
        5,
        {
            key: 'updated_at',
            direction: 'desc',
        },
        false,
        false,
        true
    );
    searchInput = null;
    constructor(private service: ShipmentsService, private router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    public onSearchKeyEnter(_e: any) {
        if (this.searchInput) {
            this.router.navigate(['/my-portal', 'shipments', 'list'], {
                queryParams: {
                    query_key: 'freetext',
                    query_value: this.searchInput,
                },
            });
            if (this.parentDropdown) {
                this.parentDropdown.close();
            }
            this.searchInput = null;
        }
    }
}
