<button
    class="btn {{buttonClass}} {{state === 'success' ? successClass : null}} {{state === 'failed' ? failedClass : null}}"
    [disabled]="state === 'busy' && disableOnBusy || disabled" [attr.type]="type">
    <span class="icon-container mr-2" [@iconAnimation]="state"
        *ngIf="(defaultIcon && state ==='default') || (busyIcon && state ==='busy') || (successIcon && state ==='success') || (failedIcon && state ==='failed')">
        <fa-icon [icon]="defaultIcon" *ngIf="defaultIcon && state ===  'default'" [fixedWidth]="true"></fa-icon>
        <fa-icon [icon]="busyIcon" *ngIf="busyIcon && state ===  'busy'" pulse="true" [fixedWidth]="true"></fa-icon>
        <fa-icon [icon]="successIcon" *ngIf="successIcon && state ===  'success'" [fixedWidth]="true"></fa-icon>
        <fa-icon [icon]="failedIcon" *ngIf="failedIcon && state ===  'failed'" [fixedWidth]="true"></fa-icon>
    </span>
    <span *ngIf="state === 'default'">{{ defaultLabel }}</span>
    <span *ngIf="state === 'busy'">{{ busyLabel || defaultLabel }}</span>
    <span *ngIf="state === 'success'">{{ successLabel || defaultLabel }}</span>
    <span *ngIf="state === 'failed'">{{ failedLabel || defaultLabel }}</span>
</button>
