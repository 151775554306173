import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ShipmentsState } from './../portal.state';
import * as shipmentActions from './../actions/shipments.actions';

export const selectFeature = createFeatureSelector<ShipmentsState>('shipments');

export const selectShipmentActivity = createSelector(
    selectFeature,
    (state: ShipmentsState) => state.activity
);

export const selectShipment = createSelector(
    selectFeature,
    (state: ShipmentsState) => state.shipment
);

export const isLoadingShipment = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        !state.done && state.action === shipmentActions.loadShipment.type
);

export const loadingShipmentError = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        state.done &&
        state.error &&
        state.action === shipmentActions.loadShipment.type
            ? state.error
            : null
);

export const isLoadingShipmentActivity = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        !state.done &&
        state.action === shipmentActions.loadShipmentActivity.type
);

export const loadingShipmentActivityError = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        state.done &&
        state.error &&
        state.action === shipmentActions.loadShipmentActivity.type
);

export const isLoadingMoreShipmentActivity = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        !state.done &&
        state.action === shipmentActions.loadMoreShipmentActivity.type
);

export const isPostingComment = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        !state.done && state.action === shipmentActions.postComment.type
);

export const hasPostingCommentFailed = createSelector(
    selectFeature,
    (state: ShipmentsState) =>
        state.done &&
        state.error &&
        state.action === shipmentActions.postComment.type
);
