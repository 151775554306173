import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';

import {
    map,
    tap,
    switchMap,
    catchError,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SiteWideSearchService } from './../../services/site-wide-search.service';

@Component({
    selector: 'qw-site-wide-search',
    templateUrl: './site-wide-search.component.html',
    styleUrls: ['./site-wide-search.component.scss'],
})
export class SiteWideSearchComponent implements OnInit {
    @HostBinding('class.w-100') fullWidth = true;
    public isSearching = false;
    public searchValue = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public results$: Observable<any> | null;

    @ViewChild('dropDown', { static: true }) dropDown: NgbDropdown;

    private searchValue$: Subject<string> = new Subject();

    constructor(private service: SiteWideSearchService) {}

    ngOnInit() {
        this.initSearch();
    }
    initSearch() {
        this.results$ = null;

        this.results$ = this.searchValue$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.isSearching = true)),
            switchMap(term => {
                if (!term || term.length < 3) {
                    return of(null);
                }
                return this.service.search(term).pipe(map(a => a.hits));
            }),
            catchError(() => of(null)),
            tap(() => (this.isSearching = false))
        );
    }

    public onSearchInput(event: KeyboardEvent) {
        const value = (event.target as HTMLInputElement).value;
        this.searchValue$.next(value);
    }

    public resultClick() {
        this.searchValue$.next('');
        this.searchValue = null;
        this.initSearch();
    }
}
