<div #rootElement class="card" [ngClass]="{'lg': size === 'lg'}" [@fadeInOut]>
    <div class="card-body p-3 flex-shrink-0">
        <fa-icon [size]="size === 'lg' ? 'lg' : null" [icon]="['far', isSearching ? 'spinner' : 'map-pin']"
            [pulse]="isSearching" class="text-primary" [fixedWidth]="true"></fa-icon>
        <input #searchInput type="search" class="form-control" [placeholder]="inputPlaceholder"
            [ngClass]="{'form-control-lg': size === 'lg'}" [value]="selectedItemLabel"
            (keydown.Tab)="onConfirmKeys($event)" (keyup.enter)="onConfirmKeys($event)"
            (input)="autocompleteInputChanged($event)" />
    </div>

    <ul class="lta-suggestions-list list-group list-group-flush">
        <ng-container *ngIf="suggestions; else: placeholder">
            <a (click)="itemSelected(suggestion)"
                class="lta-suggestion-item list-group-item list-group-item-action flex-column align-items-start"
                *ngFor="let suggestion of suggestions">

                <ng-template [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                    [ngTemplateOutletContext]="{item: suggestion}"></ng-template>
            </a>
            <a *ngIf="suggestions.length === 0"
                class="lta-not-found-item list-group-item list-group-item-action list-group-item-warning flex-column align-items-start">
                <ng-template [ngTemplateOutlet]="notFoundTemplate || defaultNotFoundTemplate"></ng-template>
            </a>
        </ng-container>
    </ul>
</div>

<ng-template #placeholder>
    <a *ngIf="selectedItem" (click)="itemSelected(selectedItem)"
        class="lta-suggestion-placeholder list-group-item list-group-item-action flex-column align-items-start">
        <ng-template [ngTemplateOutlet]="placeholderTemplate || defaultItemTemplate"
            [ngTemplateOutletContext]="{item: selectedItem}"></ng-template>
    </a>
    <a
        class="lta-suggestion-hint list-group-item list-group-item-action flex-column align-items-start active text-light">
        <ng-template [ngTemplateOutlet]="hintTemplate || defaultHintTemplate"></ng-template>
    </a>
</ng-template>

<ng-template #defaultItemTemplate let-item="item">
    <div>{{ item }}</div>
</ng-template>

<ng-template #defaultHintTemplate>
    <div>{{'portal.location-typeahead.hint-start' | translate}}</div>
    <small>{{'portal.location-typeahead.hint-small' | translate}}</small>
</ng-template>

<ng-template #defaultNotFoundTemplate>
    <div>{{'portal.location-typeahead.not-found' | translate}}</div>
    <small>{{'portal.location-typeahead.not-found-small' | translate}}</small>
</ng-template>
