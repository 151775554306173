import { DashboardService } from './../../../services/dashboard.service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'qw-list-view-widget',
    templateUrl: './list-view-widget.component.html',
    styleUrls: ['./list-view-widget.component.scss'],
})
export class ListViewWidgetComponent {
    private _widgetConfig;

    @Input()
    public set widgetConfig(cfg) {
        this._widgetConfig = cfg;
        this.loadData();
    }

    public get widgetConfig() {
        return this._widgetConfig;
    }

    public data$;

    constructor(private service: DashboardService) {}

    private loadData() {
        this.data$ = this.service.loadDashboardData(
            this.widgetConfig.dataset.endpoint
        );
    }
}
