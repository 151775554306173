import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'bypassSecurityTrustResourceUrl',
})
export class BypassSecurityTrustResourceUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
