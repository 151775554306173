import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'lineBreaks',
})
export class LineBreaksPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        if (value) {
            if (value.includes('\\n')) {
                return value.replaceAll('\\n', '<br />');
            }
            // eslint-disable-next-line no-control-regex
            return value.replace(new RegExp('\n', 'g'), '<br />');
        }
        return value;
    }
}
