import { Component, Input } from '@angular/core';
import {
    trigger,
    transition,
    query,
    style,
    animate,
    sequence,
    group,
} from '@angular/animations';

@Component({
    selector: 'qw-state-button',
    templateUrl: './state-button.component.html',
    styleUrls: ['./state-button.component.scss'],
    animations: [
        trigger('iconAnimation', [
            transition('* => *', [
                // query(':enter', style({ transform: 'scale(0.5)', opacity: 0 }), {
                //     optional: true,
                // }),
                group([
                    query(
                        ':leave',
                        style({ transform: 'scale(1)', opacity: 1 }),
                        {
                            optional: true,
                        }
                    ),
                    query(
                        ':enter',
                        style({ transform: 'scale(0)', opacity: 0 }),
                        {
                            optional: true,
                        }
                    ),
                ]),
                group([
                    group([
                        query(
                            ':leave',
                            animate(
                                '300ms 0ms ease-in-out',
                                style({ transform: 'scale(0)', opacity: 0 })
                            ),
                            {
                                optional: true,
                            }
                        ),
                        query(
                            ':enter',
                            sequence([
                                animate(
                                    '300ms 0ms ease-in-out',
                                    style({
                                        transform: 'scale(1.25)',
                                        opacity: 1,
                                    })
                                ),
                                animate(
                                    '100ms 0ms ease-in-out',
                                    style({ transform: 'scale(1)', opacity: 1 })
                                ),
                            ]),
                            {
                                optional: true,
                            }
                        ),
                    ]),
                ]),
            ]),
        ]),
    ],
})
export class StateButtonComponent {
    // Button scaffold
    @Input() type: 'submit' | 'reset' | 'button' = 'submit';

    // Labels
    @Input() defaultLabel: string | null = 'State Button';
    @Input() busyLabel: string | null;
    @Input() successLabel: string | null;
    @Input() failedLabel: string | null;

    // Classes
    @Input() buttonClass: string | null;
    @Input() successClass: string | null;
    @Input() failedClass: string | null;

    // Icons
    @Input() defaultIcon: string[] | null = null;
    @Input() busyIcon: string[] | null = ['far', 'spinner'];
    @Input() successIcon: string[] | null = ['far', 'check'];
    @Input() failedIcon: string[] | null = ['far', 'exclamation'];

    // Transitions and state
    @Input() clearSuccessDelay = 1000;
    @Input() clearFailedDelay = 1000;
    @Input() disableOnBusy = true;
    @Input() disabled = false;

    // State
    // tslint:disable-next-line: variable-name
    private _state: 'default' | 'busy' | 'success' | 'failed' = 'default';
    @Input()
    set state(state) {
        if (state !== this.state) {
            if (this.clearTimer) {
                clearTimeout(this.clearTimer);
            }

            this._state = state;

            if (state === 'success') {
                this.setClearTimeout('success', this.clearSuccessDelay);
            } else if (state === 'failed') {
                this.setClearTimeout('failed', this.clearFailedDelay);
            }
        }
    }

    get state() {
        return this._state;
    }

    private clearTimer;

    private setClearTimeout(
        state: string,
        delay: number,
        returnState: 'default' | 'success' | 'failed' | 'busy' = 'default'
    ) {
        if (delay && delay > 0) {
            this.clearTimer = setTimeout(() => {
                if (this._state === state) {
                    this._state = returnState;
                }
            }, delay);
        }
    }
}
