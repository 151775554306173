<div class="bg-white min-vh-100">
    <div class="container py-3 pb-5" *ngIf="selectedQuotation$ | async; let quotation">
        <div class="small text-muted pb-2">
            <a class="text-muted" [routerLink]="['/my-portal']">My Portal</a>
            &rsaquo;
            <a [routerLink]="['/my-portal/quotations']" class="text-muted">Quotations</a>
            &rsaquo;
            <a [routerLink]="['./']" class="font-weight-bold text-muted">
                <span *ngIf="quotation.request.owner_reference">{{ quotation.request.owner_reference}}</span>
                <span *ngIf="!quotation.request.owner_reference">
                    {{ quotation.request.transport_mode | titlecase }}
                    {{ quotation.request.load_type | uppercase }}
                    {{ quotation.request.origin.name }} to
                    {{ quotation.request.destination.name }}
                </span>
            </a>
        </div>
        <div class="border-bottom border-top d-flex mb-3 py-2 align-items-center">
            <div class="flex-grow-1">
                <span class="text-muted">
                    Created
                    <span [ngbTooltip]="quotation.request.created_at | amLocal | amDateFormat: 'LLL'"
                        placement="bottom">{{ quotation.request.created_at | amLocal | amTimeAgo }}</span>
                    by
                    <a href="javascript:void(0)" [ngbPopover]="headerUserPopoverContent" placement="bottom"
                        triggers="mouseenter:mouseleave"> {{ quotation.owner.name }}</a>
                    <ng-template #headerUserPopoverContent>
                        <qwyk-user-info-tooltip [user]="quotation.owner"></qwyk-user-info-tooltip>
                    </ng-template>
                </span>
            </div>
            <div>
                <button class="btn btn-outline-secondary" (click)="download(quotation)" [disabled]="isDownloading">
                    <fa-icon [icon]="['far', !isDownloading ? 'file-download' : 'spinner']" [pulse]="isDownloading"
                        class="mr-2"></fa-icon>
                    Download
                </button>
                <button class="btn btn-outline-secondary ml-2" (click)="share()" *ngIf="quotation.is_owner">
                    <fa-icon [icon]="['far', 'user-plus']" class="mr-2"></fa-icon>
                    Share
                    <span *ngIf="quotation.shared_users.length > 0" class="badge badge-secondary px-2 py-0 ml-1">
                        {{ quotation.shared_users.length }}
                    </span>
                </button>
                <button class="btn btn-outline-secondary ml-2" [routerLink]="['/my-portal', 'booking-wizard', 'cargo']"
                    [queryParams]="{'copy': quotation.id}">
                    <fa-icon [icon]="['far', 'redo']" class="mr-2"></fa-icon>
                    Re-quote
                </button>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-between mb-5">
            <h3 class="font-weight-normal m-0">
                <span *ngIf="quotation.request.owner_reference">{{ quotation.request.owner_reference}}</span>
                <span *ngIf="!quotation.request.owner_reference">
                    {{ quotation.request.transport_mode | titlecase }}
                    {{ quotation.request.load_type | uppercase }}
                    {{ quotation.request.origin.name }} to
                    {{ quotation.request.destination.name }}
                </span>
            </h3>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <fa-icon [icon]="['far', 'sort-amount-down']"></fa-icon>
                        </span>
                    </div>
                    <select class="custom-select" [(ngModel)]="sortOrder">
                        <option value="-total_amount">Price [Cheapest]</option>
                        <option value="+total_amount">Price [Most Expensive]</option>
                        <option value="-transit_time">Transit time [Fastest]</option>
                        <option value="+transit_time">Transit time [Slowest]</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4 border-right">
                <div class="" *ngIf="quotation.request; let request">
                    <qwyk-quotation-request-summary [quotation]="quotation"></qwyk-quotation-request-summary>
                    <!-- <hr>
                    <button class="btn btn-sm btn-link pl-0">
                        Quote again
                        <fa-icon [icon]="['far', 'arrow-right']" class="ml-1"></fa-icon>
                    </button> -->

                </div>
                <div class="mt-4" *ngIf="quotation.is_owner">
                    <qwyk-request-for-quotation-offer
                        *ngIf="!quotation.last_rate_request?.open && (offers$ | async).length > 0"
                        [quotation]="quotation">
                    </qwyk-request-for-quotation-offer>
                    <ng-container *ngIf="quotation.last_rate_request?.open">
                        <hr>
                        <h5 class="font-weight-normal">Pending rate request</h5>
                        <p class="small text-muted mb-0">
                            {{ quotation.last_rate_request.created_at | amTimeAgo }}
                            <a href="javascript:void(0)" [ngbPopover]="rateRequestOwnerPopoverContent"
                                placement="bottom" triggers="mouseenter:mouseleave">
                                {{ quotation.last_rate_request.creator?.name }}
                            </a>
                            requested a better rate:
                        </p>

                        <div><span class="text-muted">Reason:</span> {{ quotation.last_rate_request.reason }}</div>
                        <p *ngIf="quotation.last_rate_request.remarks"><span class="text-muted">Remarks:</span>
                            {{ quotation.last_rate_request.remarks }}</p>



                        <ng-template #rateRequestOwnerPopoverContent>
                            <qwyk-user-info-tooltip [user]="quotation.last_rate_request.creator">
                            </qwyk-user-info-tooltip>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="quotation.last_rate_request && !quotation.last_rate_request?.open">
                        <hr>
                        <h5 class="font-weight-normal">Previous rate request</h5>
                        <p class="small text-muted mb-0">
                            {{ quotation.last_rate_request.created_at | amTimeAgo }}
                            <a href="javascript:void(0)" [ngbPopover]="rateRequestOwnerPopoverContent"
                                placement="bottom" triggers="mouseenter:mouseleave">
                                {{ quotation.last_rate_request.creator.name }}
                            </a>
                            requested a better rate:
                        </p>
                        <div><span class="text-muted">Reason:</span> {{ quotation.last_rate_request.reason }}</div>
                        <p *ngIf="quotation.last_rate_request.remarks"><span class="text-muted">Remarks:</span>
                            {{ quotation.last_rate_request.remarks }}</p>

                        <p class="small text-muted mb-0">
                            {{ quotation.last_rate_request.updated_at | amTimeAgo }}
                            <a href="javascript:void(0)" [ngbPopover]="rateRequestResponderPopoverContent"
                                placement="bottom" triggers="mouseenter:mouseleave">
                                {{ quotation.last_rate_request.responder?.name }}
                            </a>
                            responded with:
                        </p>
                        <div>{{ quotation.last_rate_request.response_message }}</div>


                        <ng-template #rateRequestResponderPopoverContent>
                            <qwyk-user-info-tooltip [user]="quotation.last_rate_request.responder">
                            </qwyk-user-info-tooltip>
                        </ng-template>
                        <ng-template #rateRequestOwnerPopoverContent>
                            <qwyk-user-info-tooltip [user]="quotation.last_rate_request.creator">
                            </qwyk-user-info-tooltip>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <div class="col-8">
                <ng-container *ngIf="offers$ | async; let offers;">
                    <ng-container *ngIf="offers.length > resultsPageSize">
                        <div class="small text-muted text-right">{{(resultsPage-1) * resultsPageSize + 1}} to {{
                            resultsPage *
                            resultsPageSize }} of {{offers.length}}</div>
                    </ng-container>
                    <qwyk-quotation-offer-list-item
                        *ngFor="let offer of offers | slice: (resultsPage-1) * resultsPageSize : resultsPage * resultsPageSize"
                        [offer]="offer" [quotation]="quotation">
                    </qwyk-quotation-offer-list-item>
                    <ngb-pagination *ngIf="offers.length > resultsPageSize" [(page)]="resultsPage" size="sm"
                        class="d-flex justify-content-center" [pageSize]="resultsPageSize" [maxSize]="5" [rotate]="true"
                        [ellipses]="false" [boundaryLinks]="true" [collectionSize]="offers.length"></ngb-pagination>
                    <div *ngIf="offers.length === 0" [@fadeIn] class="text-center">
                        <h5 class="font-weight-light font-weight-normal">We're sorry but we weren't able to find any
                            rates for your request
                        </h5>
                        <p>
                            Although we couldn't give you an automated rate, we might still be able to quote you one.
                            Please
                            fill
                            out this form and someone will get back to you as soon as possible.
                        </p>
                        <div class="card">
                            <div class="card-body">
                                <qwyk-request-for-quotation-offer [quotation]="quotation" [noRatesReason]="true">
                                </qwyk-request-for-quotation-offer>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
