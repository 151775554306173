<ng-template #activitiesNotYetLoaded>
    <qw-loading-indicator text="Loading shipment activities..." componentClass="text-muted m-4" size="sm">
    </qw-loading-indicator>
</ng-template>
<ng-container *ngIf="user$ | async; let user;">
    <ng-container *ngIf="activity$ | async; let activities; else activitiesNotYetLoaded">
        <ul class="activity-list">
            <li class="text-muted text-center my-5" *ngIf="activities.data.length === 0">
                No activity on this shipment yet.
            </li>
            <li [@fadeIn] class="activity-item" *ngFor="let activity of activities.data; index as idx">
                <div class="activity-item-inner">
                    <ng-container [ngSwitch]="activity.type">
                        <ng-container *ngSwitchCase="'status-update'">
                            <div class="activity-icon border">
                                <fa-icon [icon]="['far', 'clock']" class="text-muted" [fixedWidth]="true" size="lg">
                                </fa-icon>
                            </div>
                            <div class="activity-content text-muted">
                                <a href="javascript:void(0)" class="text-body font-weight-bold"
                                    [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                    triggers="mouseenter:mouseleave"> {{ activity.user?.name || '@system' }}</a>
                                <ng-template #headerUserPopoverContent>
                                    <qwyk-user-info-tooltip *ngIf="activity.user" [user]="activity.user"></qwyk-user-info-tooltip>
                                </ng-template>
                                updated the status to
                                <a [routerLink]="['./']" [queryParams]="{view: 'milestones'}"
                                    class="text-body font-weight-bold">{{ activity.data.new_status }}</a>
                                <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                    placement="bottom">
                                    {{ activity.created_at | amLocal | amTimeAgo }}
                                </span>
                                <div *ngIf="activity.data.notes">Note: {{ activity.data.notes }}</div>
                                <div *ngIf="activity.data.reference">Reference: {{ activity.data.reference }}</div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'follow-changed'">
                            <div class="activity-icon border">
                                <fa-icon [icon]="['far', 'star']" [fixedWidth]="true" size="lg">
                                </fa-icon>
                            </div>
                            <div class="activity-content text-muted">
                                <a href="javascript:void(0)" class="text-body font-weight-bold"
                                    [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                    triggers="mouseenter:mouseleave"> {{ activity.user?.name }}</a>
                                <ng-template #headerUserPopoverContent>
                                    <qwyk-user-info-tooltip [user]="activity.user"></qwyk-user-info-tooltip>
                                </ng-template>&nbsp;
                                <b>started following</b> this shipment
                                <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                    placement="bottom">
                                    {{ activity.created_at | amLocal | amTimeAgo }}
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'document'">
                            <div class="activity-icon border">
                                <fa-icon [icon]="['far', 'file']" class="text-muted" [fixedWidth]="true" size="lg">
                                </fa-icon>
                            </div>
                            <div class="activity-content text-muted">
                                <div class="activity-content-header">
                                    <a href="javascript:void(0)" class="text-body font-weight-bold"
                                        [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                        triggers="mouseenter:mouseleave"> {{ activity.user?.name }}</a>
                                    <ng-template #headerUserPopoverContent>
                                        <qwyk-user-info-tooltip [user]="activity.user"></qwyk-user-info-tooltip>
                                    </ng-template>
                                    attached
                                    <a [routerLink]="['./']" [queryParams]="{view: 'documents'}"
                                        class="text-body font-weight-bold">
                                        {{ activity.data.filename || 'Document '}}
                                    </a>
                                    <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                        placement="bottom">
                                        {{ activity.created_at | amLocal | amTimeAgo }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'comment'">
                            <div class="card">
                                <div class="card-body pt-3 px-3 pb-0">
                                    <div class="d-flex">
                                        <div class="flex-grow-1 text-muted">
                                            <a href="javascript:void(0)" class="text-body font-weight-bold"
                                                [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                                triggers="mouseenter:mouseleave"> {{ activity.user?.name }}</a>
                                            <ng-template #headerUserPopoverContent>
                                                <qwyk-user-info-tooltip [user]="activity.user"></qwyk-user-info-tooltip>
                                            </ng-template>
                                            &bull;
                                            <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                                placement="bottom">
                                                {{ activity.created_at | amLocal | amTimeAgo }}
                                            </span>
                                            <span class="badge badge-primary"
                                                ngbTooltip="This message was received via email"
                                                *ngIf="activity.data.received_via === 'email'">
                                                <fa-icon [icon]="['fas', 'envelope-open']" class="mr-1"></fa-icon>Email
                                            </span>
                                        </div>
                                        <div>
                                            <a *ngIf="user.id === activity.user?.id" href="javascript:void(0);"
                                                (click)="deleteComment(idx, activity)" class="text-muted mr-2"
                                                ngbTooltip="Delete comment">
                                                <fa-icon [icon]="['far', activity?.deleting ? 'spinner' : 'trash']"
                                                    [pulse]="activity?.deleting" size="sm"></fa-icon>
                                            </a>
                                            <!-- <a href="javascript:void(0);" (click)="null" class="text-muted"
                                                ngbTooltip="Reply to comment">
                                                <fa-icon [icon]="['far', 'reply']" size="sm"></fa-icon>
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body py-0 px-3" style="max-height: 180px; overflow: auto;">
                                    <div *ngIf="activity.data.received_via === 'email'" class="my-2">
                                        <b>From:</b> {{ activity.data.received_from}}<br />
                                        <b>Subject:</b> {{ activity.data.subject }}
                                        <div *ngIf="activity.data.attachments && activity.data.attachments.length > 0">
                                            <b>Attachments: </b>
                                            <a class="badge badge-light mr-1" style="cursor: pointer;" [routerLink]="[]"
                                                [queryParams]="{view: 'documents', focus: attachment.id}"
                                                *ngFor="let attachment of activity.data.attachments">
                                                <fa-icon [icon]="['far', 'paperclip']" class="mr-1"></fa-icon>
                                                {{ attachment.filename }}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="activity-comment-text my-1" [innerHTML]="activity.data.comment">
                                    </div>
                                </div>
                                <div class="card-body  pt-1 px-3 pb-3">
                                    <div>
                                        <a href="javascript:void(0)" (click)="toggleExpandedActivity(idx)"
                                            [ngClass]="{'disabled': activity.replies.length === 0}">
                                            <fa-icon
                                                [icon]="['far', expandedActivityIdx === idx ? 'angle-up' : 'angle-down']"
                                                class="mr-1">
                                            </fa-icon>
                                            {{ activity.replies.length }} replies
                                        </a>
                                    </div>
                                    <ul [@expandCollapse2] class="list-group list-group-flush list-group-sm"
                                        *ngIf="expandedActivityIdx === idx">
                                        <li class="list-group-item border-top-0 border-bottom-0"
                                            *ngFor="let reply of activity.replies">
                                            <div class="text-muted">
                                                <a class="font-weight-bold text-body" href="javascript:void(0)">{{
                                                    reply.user.name }}</a>
                                                &bull; {{ reply.created_at | amLocal | amTimeAgo }}
                                            </div>
                                            <div class="my-1 activity-comment-text" [innerHTML]="reply.text"></div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'schedule-change'">
                            <div class="activity-icon border">
                                <fa-icon [icon]="['far','ship']" class="text-muted" [fixedWidth]="true" size="lg">
                                </fa-icon>
                            </div>
                            <div class="activity-content text-muted">
                                <div class="activity-content-header">
                                    <a href="javascript:void(0)" class="text-body font-weight-bold"
                                        [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                        triggers="mouseenter:mouseleave"> {{ activity.user?.name }}</a>
                                    <ng-template #headerUserPopoverContent>
                                        <qwyk-user-info-tooltip [user]="activity.user"></qwyk-user-info-tooltip>
                                    </ng-template>
                                    update the
                                    <b class="text-body font-weight-bold">
                                        Transport Details
                                    </b>
                                    <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                        placement="bottom">
                                        {{ activity.created_at | amLocal | amTimeAgo }}
                                    </span>
                                    <div *ngIf="activity.data | objectToKVP; let changes" class="small">
                                        <div *ngFor="let change of changes; let idx=index">
                                            {{ change.key | slugToTitle }}:
                                            {{ change.value.from }} <fa-icon [icon]="['far', 'long-arrow-right']">
                                            </fa-icon>
                                            {{ change.value.to }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'milestone-change'">
                            <div class="activity-icon border">
                                <fa-icon [icon]="['far', 'calendar-edit']" class="text-muted" [fixedWidth]="true"
                                    size="lg">
                                </fa-icon>
                            </div>
                            <div class="activity-content text-muted">
                                <div class="activity-content-header">
                                    <a href="javascript:void(0)" class="text-body font-weight-bold"
                                        [ngbPopover]="headerUserPopoverContent" placement="bottom"
                                        triggers="mouseenter:mouseleave"> {{ activity.user?.name }}</a>
                                    <ng-template #headerUserPopoverContent>
                                        <qwyk-user-info-tooltip [user]="activity.user"></qwyk-user-info-tooltip>
                                    </ng-template>
                                    updated the expected
                                    <b class="text-body font-weight-bold">
                                        {{ activity.data.milestone_label }}
                                    </b>
                                    <span [ngbTooltip]="activity.created_at | amLocal | amDateFormat: 'LLL'"
                                        placement="bottom">
                                        {{ activity.created_at | amLocal | amTimeAgo }}
                                    </span>
                                    <div *ngIf="activity.data" class="small">
                                        {{ activity.data.expected_timestamp | amParseZone | amDateFormat: 'L' }}
                                        <span *ngIf="activity.data.location">@ {{ activity.data.location }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </li>
            <li class="activity-item" *ngIf="activities.meta.remaining > 0">
                <div class="activity-item-inner">
                    <div class="activity-icon border">
                        <fa-icon [icon]="['far', (isLoadingMoreActivity$ | async) ? 'spinner' : 'ellipsis-h']"
                            [pulse]="isLoadingMoreActivity$ | async" [fixedWidth]="true" size="lg"></fa-icon>
                    </div>
                    <div class="activity-content text-muted">
                        <a href="javascript:void(0)" (click)="loadMoreActivities()"
                            [ngClass]="{'disabled': (isLoadingMoreActivity$ | async)}">
                            View
                            {{activities.meta.remaining > maxMoreActivityLoad ? maxMoreActivityLoad :
                            activities.meta.remaining }}
                            more updates...
                        </a>
                    </div>
                </div>
            </li>
            <li class="activity-item" *ngIf="!disableComments">
                <div class="activity-item-inner">
                    <div class="activity-icon border">
                        <fa-icon [icon]="['far', (isPostingComment$ | async) === false ? 'comment' : 'spinner']"
                            [pulse]="isPostingComment$ | async" [fixedWidth]="true" size="lg"></fa-icon>
                    </div>
                    <div class="activity-content">
                        <form style="position: relative; top: -5px;" [formGroup]="commentForm"
                            (submit)="postNewComment()">
                            <textarea id="comment" name="comment" formControlName="comment"
                                class="form-control form-control-sm" rows="4" placeholder="Write a comment..."
                                maxlength="500"></textarea>
                            <button type="submit" class="btn btn-primary btn-sm mt-3"
                                [disabled]="commentForm.invalid || commentForm.disabled">
                                Comment</button>
                        </form>
                    </div>
                </div>
            </li>
        </ul>
    </ng-container>
</ng-container>
