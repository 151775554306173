import { Component } from '@angular/core';

@Component({
    selector: 'qw-mock-page',
    templateUrl: './mock-page.component.html',
    styleUrls: ['./mock-page.component.scss'],
})
export class MockPageComponent {
    constructor() {}
}
