/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[qwLocationTypeaheadItem]',
})
export class LocationTypeaheadItemDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[qwLocationTypeaheadPlaceholder]',
})
export class LocationTypeaheadPlaceholderDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[qwLocationTypeaheadHint]',
})
export class LocationTypeaheadHintDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[qwLocationTypeaheadNotFound]',
})
export class LocationTypeaheadNotFoundDirective {
    constructor(public template: TemplateRef<any>) {}
}
