import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';

@Pipe({
    name: 'purchaseOrderStatusIcon',
})
export class PurchaseOrderStatusIconPipe implements PipeTransform {
    private _icons: { [index: string]: string } =
        Constants.PURCHASEORDER_STATUS_ICONS;

    transform(value: string): string {
        const result = this._icons[value];
        if (result) {
            return result;
        } else {
            return value;
        }
    }
}
