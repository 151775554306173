<!-- <form class="form-inline w-100"> -->
<ng-template #resultTemplate let-r="result">
    <div [routerLink]="['/my-portal/shipments/', r.id]" (click)="resultClick()">
        <div [innerHTML]="r.name"></div>
        <div class="small">
            <div *ngFor="let highlight of r.highlight">
                {{ highlight.name }}: <span [innerHTML]="highlight.value"></span>
            </div>
        </div>
    </div>
</ng-template>
<!-- <input name="search" type="text" class="form-control form-control-sm w-100" placeholder="Search..."
        [(ngModel)]="searchValue" [ngbTypeahead]="search" [resultTemplate]="resultTemplate" /> -->

<div ngbDropdown #dropDown="ngbDropdown" class="site-wide-search-dropdown d-inline-block w-100">
    <input type="text" class="search-input form-control form-control-sm w-100" ngbDropdownToggle style="min-width: 200px;"
        (input)="onSearchInput($event)" placeholder="Search or go to..." [(ngModel)]="searchValue"
        [ngModelOptions]="{standalone: true}" />
    <div ngbDropdownMenu aria-labelledby=" dropdownBasic1" style="padding-top: 2px; padding-bottom: 2px;">
        <ng-container *ngIf="(results$ | async) === false && !isSearching">
            <button ngbDropdownItem [routerLink]="['/my-portal/shipments/list']">
                <fa-icon [icon]="['far', 'box-alt']" class="mr-2" [fixedWidth]="true"></fa-icon>
                My Shipments
            </button>
            <button ngbDropdownItem [routerLink]="['/my-portal/schedules/search']">
                <fa-icon [icon]="['far', 'clock']" class="mr-2" [fixedWidth]="true"></fa-icon>
                Schedule Search
            </button>
            <button ngbDropdownItem [routerLink]="['/my-portal/booking-wizard/routing']">
                <fa-icon [icon]="['far', 'hands-usd']" class="mr-2" [fixedWidth]="true"></fa-icon>
                Quotation Search
            </button>
            <!-- <button ngbDropdownItem>Something else is here</button> -->
        </ng-container>

        <ng-container *ngIf="results$ | async; let results">
            <div ngbDropdownItem *ngIf="results.length === 0 && !isSearching">
                Nothing Found
            </div>
            <button ngbDropdownItem *ngFor="let r of results" class="border-bottom py-2"
                [routerLink]="r.sys_state === 'DRAFT' || r.sys_state === 'SENDING_FAILED' ? ['/my-portal/booking/new/', r.id] : ['/my-portal/shipments/', r.id]"
                (click)="resultClick()">
                <div class="dropdown-button-content">
                    <div class="d-flex justify-content-between">
                        <span [innerHTML]="r.name" style="text-overflow: ellipsis;" class="overflow-hidden"></span>
                        <span class="badge badge-secondary ml-1"
                            *ngIf="r.sys_state === 'DRAFT' || r.sys_state === 'SENDING_FAILED'">Draft</span>
                    </div>
                    <div class="small text-muted">
                        <div *ngFor="let highlight of r.highlight">
                            {{ highlight.name }}: <span [innerHTML]="highlight.value"></span>
                        </div>
                    </div>
                </div>
            </button>
            <div class="px-4 py-1">
                <img src="https://cdn.qwyk.io/images/logos/search-by-algolia-light-background.svg"
                    style="height: 16px;">
            </div>
        </ng-container>
        <ng-container *ngIf="isSearching">
            <button ngbDropdownItem>
                Searching...
            </button>
        </ng-container>
    </div>
</div>
<!-- </form> -->
