<div class="container py-3 pb-5 mb-5">
    <div class="small text-muted mb-3 border-bottom pb-2">
        <a [routerLink]="['/my-portal']" class="text-muted">My Portal</a>
        &rsaquo;
        <a [routerLink]="['./']" class="font-weight-bold text-muted">Shipments</a>
    </div>
    <div class="d-flex justify-content-between items-align-center">
        <h3 class="font-weight-normal m-0">Your active shipments</h3>
        <div class="flex-grow-0">
            <button [routerLink]="['/my-portal/booking/new/choose']" class="btn btn-primary btn-sm">
                Create new shipment
            </button>
        </div>
    </div>
    <div *ngIf="(queryParams$ | async).focus === 'copy'" class="alert alert-info my-4">
        Find a shipment and click
        <fa-icon [icon]="['far', 'copy']" class="mx-1"></fa-icon>
        Copy.
    </div>
    <ul class="list-group list-group-flush mt-3">
        <li class="list-group-item bg-light border">
            <form>
                <qw-query-builder (loadSuggestions)="loadQueryBuilderSuggestions($event)" (search)="search($event)"
                                  [disabled]="queryBuilderDisabled"
                                  [loadingSuggestions]="loadingQueryBuilderSuggestions"
                                  [queryTokens]="queryBuilderQueryTokens" [recentSearches]="recentSearches$"
                                  [sortOptions]="queryBuilderSortOptions"
                                  [suggestions]="queryBuilderSuggestions"
                                  [tokens]="queryBuilderTokens" size="sm">
                </qw-query-builder>
            </form>
        </li>
        <li *ngIf="loadingShipments" class="list-group-item text-center text-muted border-bottom">
            <qw-loading-indicator componentClass="text-muted" size="sm" text="Loading shipments...">
            </qw-loading-indicator>
        </li>
        <ng-container *ngIf="shipments$ | async; let shipments">
            <li *ngIf="shipments.data.length === 0" class="list-group-item text-center text-muted border-bottom">
                <fa-icon [icon]="['far', 'search']" size="2x"></fa-icon>
                <h6 class="mt-2">We didn't find any shipments to display</h6>
                <p>Adjust your search parameters, or start by booking a new shipment.</p>
                <button [routerLink]="['/my-portal/booking/new/choose']" class="btn btn-outline-secondary btn-sm">
                    Book new shipment
                </button>
            </li>
            <ng-container *ngFor="let shipment of shipments.data; index as idx;">
                <li class="list-group-item list-group-item-action d-flex flex-column py-3">
                    <div class="d-flex">
                        <div class="flex-grow-1 font-weight-bold text-dark">

                            <h6 class="mb-1"><a [routerLink]="shipment.sys_state === 'DRAFT' || shipment.sys_state === 'SENDING_FAILED' ?
                                ['../../booking/new/', shipment.id] : ['../', shipment.id]" class="text-dark" style="opacity: 0.9;">
                                <a [ngbTooltip]="shipment.status.actual_timestamp | amLocal | amDateFormat: 'LLL'" [queryParams]="{'view': 'milestones'}"
                                   [routerLink]="['../', shipment.id]"
                                   class="badge badge-secondary ml-1 rounded-pill py-0 font-weight-normal" placement="bottom"
                                   style="cursor: pointer;"
                                   style="font-size: 80% !important">
                                    <fa-icon [fixedWidth]="true" [icon]="['far', shipment.status.icon]" class="">
                                    </fa-icon>
                                    {{ shipment.status.actual_label }}
                                </a>
                                <ng-container *ngIf="!shipment.organization_shipment_reference">
                                    {{ shipment.name }}
                                </ng-container>
                                <ng-container *ngIf="shipment.organization_shipment_reference">
                                    {{ shipment.organization_shipment_reference }}
                                    <span class="font-weight-normal"> | {{ shipment.name }}</span>
                                </ng-container>
                            </a></h6>
                        </div>
                        <div>
                            <a *ngIf="(queryParams$ | async).focus === 'copy'" [@fadeIn] [queryParams]="{name: shipment.name}" [routerLink]="['../copy/', shipment.id]"
                               class="mr-1" container="body"
                               ngbTooltip="Copy this shipment or booking">
                                <fa-icon [icon]="['far', 'copy']" class="mr-1" fixedWidth="true"></fa-icon>
                                Copy
                            </a>
                            <a [ngClass]="{'show-disabled': shipment.attachment_count === 0}" [queryParams]="{'view': 'documents'}"
                               [routerLink]="['../', shipment.id]" class="text-dark" container="body"
                               ngbTooltip="Documents">
                                <fa-icon [fixedWidth]="true" [icon]="['fas', 'file']" class="mr-1"></fa-icon>
                                {{ shipment.attachment_count }}
                            </a>
                            <a (click)="0" [ngClass]="{'show-disabled': shipment.comment_count === 0}"
                               [queryParams]="{'view': 'activity'}" [routerLink]="['../', shipment.id]" class="ml-1 text-dark"
                               container="body" ngbTooltip="Comments">
                                <fa-icon [fixedWidth]="true" [icon]="['fas', 'comments']" class="mr-1"></fa-icon>
                                {{ shipment.comment_count }}
                            </a>
                            <div #myDrop="ngbDropdown" class="d-inline-block" container="body" ngbDropdown
                                 placement="bottom-end">
                                <a [ngClass]="{'rotate': myDrop.isOpen()}" class="options-dd-toggle ml-1 text-muted" container="body"
                                   href="javascript:void(0)" ngbDropdownToggle ngbTooltip="Options">
                                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'ellipsis-v']" class="d-inline-block">
                                    </fa-icon>
                                </a>
                                <div aria-labelledby="dropdownBasic1" class="p-0" ngbDropdownMenu
                                     style="min-width: 200px;">
                                    <h6 class="dropdown-header">Shipment options</h6>
                                    <button [queryParams]="{name: shipment.name}" [routerLink]="['../copy/', shipment.id]"
                                            ngbDropdownItem>
                                        <fa-icon [icon]="['far', 'copy']" class="mr-2"></fa-icon>
                                        Copy shipment
                                    </button>
                                    <button [disabled]="shipment.sys_state === 'DRAFT' || shipment.sys_state === 'SENDING_FAILED'"
                                            [queryParams]="{focus: shipment.id}"
                                            [routerLink]="['./../map']" ngbDropdownItem>
                                        <fa-icon [icon]="['far', 'map']" class="mr-2"></fa-icon>
                                        View on Map
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-1 text-muted">
                        {{ shipment.booking_number }} &bull;
                        <span [ngbTooltip]="shipment.created_at | amLocal | amDateFormat: 'LLL'" placement="bottom"
                              style="cursor: pointer;">
                        opened {{ shipment.created_at | amLocal | amTimeAgo }}
                        </span>
                        <ng-container *ngIf="shipment.owner">
                            by
                            <a [ngbPopover]="ownerUserPopoverContent" href="javascript:void(0)"
                               placement="bottom" triggers="mouseenter:mouseleave" class="text-muted">
                                <img
                                    [src]="'https://www.gravatar.com/avatar/' + (shipment.owner.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (shipment.owner.name | urlEncode) + '/24/097aff/ffffff/2//false/true/true'"
                                    class="rounded-circle border"
                                    style="width: 20px; height: 20px; margin-top: -4px">
                            </a>
                            <ng-template #ownerUserPopoverContent>
                                <qwyk-user-info-tooltip [user]="shipment.owner"></qwyk-user-info-tooltip>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="shipment.vessel">
                            <span class="ml-1">&bull;</span>
                            <ng-container [ngSwitch]="shipment.transport_mode">
                                <fa-icon *ngSwitchCase="'OCEAN'" [icon]="['far', 'ship']"
                                         class="ml-2 text-dark font-weight-bold"></fa-icon>
                                <fa-icon *ngSwitchCase="'AIR'" [icon]="['far', 'plane']"
                                         class="ml-2 text-dark font-weight-bold"></fa-icon>
                                <fa-icon *ngSwitchCase="'RAIL'" [icon]="['far', 'train']"
                                         class="ml-2 text-dark font-weight-bold"></fa-icon>
                                <fa-icon *ngSwitchCase="'TRUCK'" [icon]="['far', 'truck']"
                                         class="ml-2 text-dark font-weight-bold"></fa-icon>
                            </ng-container>
                            {{ shipment.vessel }}
                            {{ shipment.voyage }}
                        </ng-container>

                    </div>
                </li>
            </ng-container>
            <div *ngIf="shipments.meta; let paging" class="mt-3 text-center">
                <small class="text-center text-muted ">{{ paging.from }} to {{ paging.to }} of
                    {{ paging.total }}</small>
                <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="paging.total"
                                [pageSize]="paging.per_page" [page]="paging.current_page"
                                class="mt-3 d-flex justify-content-center">
                    <ng-template ngbPaginationPrevious>&lsaquo; Prev</ng-template>
                    <ng-template ngbPaginationNext>Next &rsaquo;</ng-template>
                </ngb-pagination>
            </div>
        </ng-container>
    </ul>
</div>
