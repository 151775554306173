<div class="modal-header border-bottom-0 px-4 pt-4">
    <div>
        <h4 class="modal-title" id="modal-basic-title">Attach document</h4>
        <p class="m-0">Select a file from your system and attach it to your booking</p>
    </div>
</div>
<form [formGroup]="form" (submit)="upload($event)">
    <div class="modal-body px-4">
        <div class="form-group">
            <div class="custom-file overflow-hidden">
                <input id="file" name="file" type="file" formControlName="file" class="custom-file-input"
                    [ngClass]="{'is-invalid': form.get('file').invalid && form.get('file').touched}"
                    (change)="onFileSelected($event)">
                <label class="custom-file-label" for="file" aria-describedby="inputGroupFileAddon02"
                    style="white-space: nowrap;">
                    <fa-icon [icon]="['far', 'paperclip']" class="mr-1 text-primary"></fa-icon>{{ fileBrowserLabel }}
                </label>
                <div class="invalid-feedback" *ngIf="form.get('file').invalid && form.get('file').touched">
                    Please select a file.
                </div>
            </div>
            <div class="text-danger" *ngIf="form.get('fileSize').invalid">
                This file is too large (max {{ maxFileSizeBytes * 0.0009765625 }} kb).
            </div>
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <textarea id="description" name="description" formControlName="description" rows="3" class="form-control"
                [ngClass]="{'is-invalid': form.get('description').invalid && form.get('description').touched}"
                maxlength="191"></textarea>
            <div class="invalid-feedback" *ngIf="form.get('file').invalid && form.get('file').touched">
                Maximum 191 characters.
            </div>
        </div>
        <div class="text-danger" *ngIf="error">
            There was an error uploading this file, please try again later or contact support if the problem persists.
        </div>
    </div>
    <div class="modal-footer border-top-0 pt-0 px-4 pb-4">
        <button class="btn btn-primary" type="submit" [disabled]="form.disabled">
            <fa-icon *ngIf="busy" [icon]="['far', 'spinner']" [pulse]="true" class="mr-2"></fa-icon>Upload
        </button>
        <button class="btn btn-link" (click)="modal.dismiss()" type="button" [disabled]="form.disabled">
            Cancel
        </button>
    </div>
</form>