import { OnInit } from '@angular/core';
import {
    Input,
    Output,
    Directive,
    ElementRef,
    EventEmitter,
} from '@angular/core';

export interface GooglePlaceAddress {
    formattedAddress?: string | null;
    streetNumber: string | null;
    route: string | null;
    locality: string | null;
    postalCode: string | null;
    state: string | null;
    countryName: string | null;
    countryCode: string | null;
    position?: { lat: number; lng: number } | null;
    types?: string[] | null;
    code: string | null;
}

@Directive({
    selector: '[qwGooglePlaces]',
})
export class GooglePlacesDirective implements OnInit {
    @Output() placeSelect: EventEmitter<GooglePlaceAddress> =
        new EventEmitter();
    @Input() placeTypes: string[] = ['address'];
    constructor(private elRef: ElementRef) {}

    public autocomplete = new google.maps.places.Autocomplete(
        this.elRef.nativeElement,
        {
            types: this.placeTypes,
        }
    );

    ngOnInit(): void {
        google.maps.event.addListener(
            this.autocomplete,
            'place_changed',
            () => {
                const formattedAddress = this.getFormattedAddress(
                    this.autocomplete.getPlace()
                );
                this.placeSelect.emit(formattedAddress);
            }
        );
    }

    private getFormattedAddress(
        place: google.maps.places.PlaceResult
    ): GooglePlaceAddress {
        const location: GooglePlaceAddress = {
            formattedAddress: null,
            streetNumber: null,
            route: null,
            locality: null,
            postalCode: null,
            state: null,
            countryName: null,
            countryCode: null,
            position: null,
            types: null,
            code: null,
        };
        for (const i in place.address_components) {
            // eslint-disable-next-line no-prototype-builtins
            if (!place.address_components.hasOwnProperty(i)) {
                continue;
            }

            const item = place.address_components[i];

            location.formattedAddress = place.formatted_address;
            location.types = place.types;
            location.position = {
                lat: 0,
                lng: 0,
            };

            if (
                place?.geometry?.location?.lat() &&
                place?.geometry?.location?.lng()
            ) {
                location.position = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
            }

            if (item.types.indexOf('locality') > -1) {
                // eslint-disable-next-line no-prototype-builtins
                if (!location.hasOwnProperty('code')) {
                    location.code = item.short_name;
                }
                location.locality = item.long_name;
            } else if (item.types.indexOf('administrative_area_level_1') > -1) {
                location.state = item.short_name;
            } else if (item.types.indexOf('street_number') > -1) {
                location.streetNumber = item.short_name;
            } else if (item.types.indexOf('route') > -1) {
                location.route = item.long_name;
            } else if (item.types.indexOf('country') > -1) {
                location.countryName = item.long_name;
                location.countryCode = item.short_name;
            } else if (item.types.indexOf('postal_code') > -1) {
                location.code = item.short_name;
                location.postalCode = item.short_name;
            }
        }

        return location;
    }
}
