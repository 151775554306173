import { Component, Input } from '@angular/core';
import { InvoicesFacade } from '@qwyk/shared-stores/invoices';

export interface RelatedInvoicesEntity {
    invoicable_type: string;
    invoicable_id: string;
}

@Component({
    selector: 'qwyk-related-invoices-list',
    templateUrl: './related-invoices-list.component.html',
    styleUrls: ['./related-invoices-list.component.scss'],
})
export class RelatedInvoicesListComponent {
    private _entity: RelatedInvoicesEntity;
    @Input() public set entity(entity: RelatedInvoicesEntity) {
        this._entity = entity;
        this.invoices.loadInvoices({
            ...entity,
            pageSize: 100000,
            is_proforma: false,
        });
    }
    public get entity(): RelatedInvoicesEntity {
        return this._entity;
    }

    public loading$ = this.invoices.loading$;
    public invoices$ = this.invoices.allInvoices$;

    constructor(private invoices: InvoicesFacade) {}
}
