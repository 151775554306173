import {
    Directive,
    HostBinding,
    Input,
    HostListener,
    EventEmitter,
    Output,
} from '@angular/core';

@Directive({
    selector: '[qwykFileDragAndDrop]',
})
export class FileDragAndDropDirective {
    @HostBinding('class.fileover') fileOver = false;
    @Input() enabled = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() fileDropped = new EventEmitter<any>();
    constructor() {}

    @HostListener('dragover', ['$event']) onDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.enabled) {
            this.fileOver = true;
        }
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event']) public onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.enabled) {
            this.fileOver = false;
            const files = e?.dataTransfer?.files || [];
            if (files.length > 0) {
                this.fileDropped.emit(files[0]);
            }
        }
    }
}
