import * as fromShipments from './../../../store/selectors/shipments.selectors';
import { AppState } from './../../../../../store/app.state';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ShipmentActivity } from './../../../store/models/shipments.models';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
    deleteComment,
    loadMoreShipmentActivity,
    loadShipmentActivity,
    postComment,
} from './../../../store/actions/shipments.actions';
import {
    expandCollapse2,
    fadeInAnimation,
} from './../../../../../helpers/reusableAnimations';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

@Component({
    selector: 'qw-shipment-activity-list',
    templateUrl: './shipment-activity-list.component.html',
    styleUrls: ['./shipment-activity-list.component.scss'],
    animations: [fadeInAnimation, expandCollapse2],
})
export class ShipmentActivityListComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public expandedActivityIdx: any;
    loadedActivityCount = 0;
    public maxMoreActivityLoad = 20;
    public user$ = this.authentication.user$;
    public commentForm: FormGroup;
    @Input()
    public disableComments = false;
    public activity$ = this.store
        .select(fromShipments.selectShipmentActivity)
        .pipe(tap(r => (this.loadedActivityCount = r ? r.data.length : 0)));
    public isLoadingMoreActivity$ = this.store.select(
        fromShipments.isLoadingMoreShipmentActivity
    );
    public isPostingComment$ = this.store.select(
        fromShipments.isPostingComment
    );
    public hasPostingCommentFailed$ = this.store.select(
        fromShipments.hasPostingCommentFailed
    );
    private isPostingCommentSubscription: Subscription;
    private hasPostingCommentFailedSubscription: Subscription;

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private authentication: AuthenticationFacade
    ) {}

    private _shipmentId: string;

    @Input()
    public set shipmentId(shipmentId: string) {
        if (this._shipmentId !== shipmentId) {
            this._shipmentId = shipmentId;
            this.store.dispatch(
                loadShipmentActivity({ shipmentId, limit: 10 })
            );
        }
    }

    ngOnInit() {
        this.commentForm = this.fb.group({
            comment: [null, [Validators.required, Validators.maxLength(500)]],
        });

        this.isPostingCommentSubscription = this.isPostingComment$.subscribe(
            isPostingComment => {
                if (isPostingComment && this.commentForm.enabled) {
                    this.commentForm.disable();
                } else {
                    this.commentForm.enable();
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.isPostingCommentSubscription) {
            this.isPostingCommentSubscription.unsubscribe();
        }

        if (this.hasPostingCommentFailedSubscription) {
            this.hasPostingCommentFailedSubscription.unsubscribe();
        }
    }

    public loadMoreActivities() {
        this.store.dispatch(
            loadMoreShipmentActivity({
                shipmentId: this._shipmentId,
                offset: this.loadedActivityCount,
                limit: this.maxMoreActivityLoad,
            })
        );
    }

    public toggleExpandedActivity(activityIdx: number): boolean {
        if (this.expandedActivityIdx === activityIdx) {
            this.expandedActivityIdx = null;
        } else {
            this.expandedActivityIdx = activityIdx;
        }
        return false;
    }

    public postNewComment() {
        if (this.commentForm.invalid) {
            return;
        }

        const data = this.commentForm.value;
        this.commentForm.reset();

        this.hasPostingCommentFailedSubscription = this.hasPostingCommentFailed$
            .pipe(first(v => v === true))
            .subscribe(hasFailed => {
                if (hasFailed) {
                    this.commentForm.patchValue(data);
                    this.toastr.error(
                        'Please try again later or contact support if the problem persists',
                        'Error positing comment'
                    );
                }
            });

        this.store.dispatch(
            postComment({
                shipmentId: this._shipmentId,
                data,
            })
        );
    }

    public deleteComment(index, activity: ShipmentActivity): void {
        this.store.dispatch(
            deleteComment({ shipmentId: this._shipmentId, activity })
        );
    }
}
