/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface QueryBuilderQueryToken {
    attribute: {
        key: string;
        label: string;
        fulltext?: boolean;
    };
    value: {
        key: string;
        label: string;
    } | null;
}

export interface QueryBuilderToken {
    key: string;
    label: string;
    icon: [string, string];
    exclusive: boolean;
}

export interface QueryBuilderSortOption {
    key: string;
    label: string;
    direction: 'asc' | 'desc';
    allowChangeDirection?: boolean;
}

export function makeQueryKVP(queryTokens) {
    return queryTokens.reduce((prev, curr) => {
        if (curr.value) {
            // eslint-disable-next-line no-prototype-builtins
            if (!prev.hasOwnProperty(curr.attribute.key)) {
                prev[curr.attribute.key] = curr.value.key;
                prev[curr.attribute.key + '_dpy'] = curr.value.label;
            } else {
                // Property already exists so we need to make the value and array and append the items
                let previous = prev[curr.attribute.key];
                let prevDpy = prev[curr.attribute.key + '_dpy'];
                if (!Array.isArray(previous)) {
                    previous = [previous];
                }
                if (!Array.isArray(prevDpy)) {
                    prevDpy = [prevDpy];
                }

                prev[curr.attribute.key] = [...previous, curr.value.key];
                prev[curr.attribute.key + '_dpy'] = [
                    ...prevDpy,
                    curr.value.label,
                ];
            }
        }

        return prev;
    }, {});
}

@Component({
    selector: 'qw-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent implements OnInit, OnDestroy {
    /**
     * The control size (null, sm or lg.)
     */
    @Input() size: null | 'sm' | 'lg';

    /**
     * Time in ms to use when debouncing the autocomplete.
     */
    @Input()
    public completeDebounceTime = 200;

    /**
     * Array of query builder tokens that can be selected.
     */
    @Input()
    public queryTokens: QueryBuilderQueryToken[] = [];

    /**
     * Array of preset tokens and their values.
     */
    @Input()
    public tokens: QueryBuilderToken[] = [];

    /**
     * Whether full text search is available.
     */
    @Input()
    public fullTextSearch = true;

    /**
     * Autocomplete Suggestions of query builder component
     */
    private _suggestions: string[] | null = [];
    public get suggestions() {
        return this._suggestions;
    }
    @Input()
    public set suggestions(value: string[] | null) {
        this._suggestions = value;
    }

    @Input()
    public loadingSuggestions = false;

    @Input()
    public disabled = false;

    @Input()
    public recentSearches: Observable<{ query: any; created_at: Date }[]>;

    private _sortOptions: QueryBuilderSortOption[];
    @Input()
    public set sortOptions(options: QueryBuilderSortOption[]) {
        this._sortOptions = options;
        if (options && options.length > 0) {
            this.selectedSortOption = options[0];
        }
    }
    public get sortOptions(): QueryBuilderSortOption[] {
        return this._sortOptions;
    }

    private _selectedSortOption: QueryBuilderSortOption;
    public set selectedSortOption(option: QueryBuilderSortOption) {
        if (
            !this._selectedSortOption ||
            this._selectedSortOption.key !== option.key
        ) {
            const wasNull = this._selectedSortOption === null;
            this._selectedSortOption = option;
            if (!wasNull) {
                this.search.next(this.queryKVP);
            }
        }
    }
    public get selectedSortOption(): QueryBuilderSortOption {
        return this._selectedSortOption;
    }

    /**
     * Emitter that is triggered when the user hits enter or clicked search.
     */
    @Output() search: EventEmitter<any> = new EventEmitter(undefined);

    /**
     * Emitter that is triggered when a new token has been selected or when the autocomplete value changes.
     */
    @Output() loadSuggestions: EventEmitter<{
        key: string;
        query: string | null;
    }> = new EventEmitter();

    @ViewChild('controlDropDown', { static: true })
    controlDropDown: NgbDropdown;
    @ViewChild('controlInput')
    controlInput;

    public editingIdx: number | null;
    public controlInputValue: string | null;

    private tokensCopy: QueryBuilderToken[] = [];
    private acicDebouncerSubscription: Subscription;
    private autocompleteInputChangedDebouncer: Subject<{
        key: string;
        query: string;
    }> = new Subject();

    ngOnInit() {
        // Create and subscribe to a debouncer for autocomplete value changes.
        this.acicDebouncerSubscription = this.autocompleteInputChangedDebouncer
            .pipe(debounceTime(this.completeDebounceTime))
            .subscribe(value => {
                if (value) {
                    this.loadSuggestions.next(value);
                }
            });

        // Create a copy of tokens so we can restore them on remove events.
        this.tokensCopy = [...this.tokens];
    }

    ngOnDestroy(): void {
        if (this.acicDebouncerSubscription) {
            this.acicDebouncerSubscription.unsubscribe();
        }
    }

    public addFTXToken(input: string): void {
        this.editingIdx =
            this.queryTokens.push({
                attribute: { key: 'freetext', label: '', fulltext: true },
                value: null,
            }) - 1;

        this.setTokenValue({ key: input, label: input });
    }

    public addQueryToken(
        token,
        loadSuggestions = true
    ): QueryBuilderQueryToken {
        this.suggestions = [];

        this.editingIdx =
            this.queryTokens.push({
                attribute: { key: token.key, label: token.label },
                value: null,
            }) - 1;

        this.controlInputValue = null;
        if (loadSuggestions) {
            this.loadSuggestions.next({ key: token.key, query: null });

            setTimeout(() => {
                this.controlDropDown.open();
                this.controlInput.nativeElement.focus();
            }, 20);
        }

        // The added token is exclusive so we cannot select it anymore.
        if (token.exclusive) {
            this.tokens.splice(
                this.tokens.findIndex(t => t.key === token.key),
                1
            );
        }

        return token;
    }

    public removeQueryToken(idx: number): void {
        if (this.disabled) {
            return;
        }

        const removedQueryToken = this.queryTokens.splice(idx, 1);

        if (removedQueryToken.length === 0) {
            return;
        }

        // Check if we need to add the token back to the list.
        if (
            !removedQueryToken[0].attribute.fulltext &&
            !this.tokens.find(t => t.key === removedQueryToken[0].attribute.key)
        ) {
            // Yep
            const replacementIdx = this.tokensCopy.findIndex(
                t => t.key === removedQueryToken[0].attribute.key
            );

            // Splicing allows us to keep the original array sorting.
            this.tokens.splice(
                replacementIdx,
                0,
                this.tokensCopy[replacementIdx]
            );
        }
    }

    public onInputBackspace(e: KeyboardEvent) {
        // The input is empty on a backspace.
        if ((e.target as HTMLInputElement).value === '') {
            // So we'll remove the last querytoken.
            this.removeQueryToken(this.queryTokens.length - 1);
            e.preventDefault();
            this.editingIdx = null;
            this.controlDropDown.close();
        }
    }

    private get queryKVP() {
        const kvp = makeQueryKVP(this.queryTokens);
        kvp.sort = this.selectedSortOption.key;
        kvp.direction = this.selectedSortOption.direction;
        return kvp;
    }

    public controlInputChanged(e) {
        if (this.editingIdx !== null) {
            this.loadingSuggestions = true;
            const token = this.queryTokens[this.editingIdx];
            this.autocompleteInputChangedDebouncer.next({
                key: token.attribute.key,
                query: (e.target as HTMLInputElement).value,
            });
        } else {
            this.controlDropDown.open();
        }
    }

    public onInputEnter(e: KeyboardEvent) {
        e.preventDefault();
        if (this.editingIdx === null) {
            if (this.controlInputValue) {
                this.addFTXToken(this.controlInputValue);
            } else {
                this.search.next(this.queryKVP);
            }
        } else {
            if (this.suggestions && this.suggestions[0]) {
                this.setTokenValue(this.suggestions[0]);
            }
        }
    }

    public setTokenValue(value) {
        if (this.editingIdx !== null && this.editingIdx > -1) {
            this.queryTokens[this.editingIdx].value = value;
        }
        this.editingIdx = null;
        this.suggestions = null;
        this.controlInputValue = null;
    }

    public onEditToken(idx) {
        if (this.disabled) {
            return;
        }
        this.editingIdx = idx;
        if (idx) {
            this.queryTokens[idx].value = null;
        }
        setTimeout(() => {
            this.controlDropDown.open();
            this.controlInput.nativeElement.focus();
        }, 20);
        this.autocompleteInputChangedDebouncer.next({
            key: this.queryTokens[idx].attribute.key,
            query: '',
        });
    }

    public setQueryFromRecentSearch(recentSearch, execute = false) {
        const query = recentSearch.query;

        const queryArray = Object.keys(query).map(e => ({
            key: e,
            value: query[e],
        }));

        // Remove all current tokens
        for (let i = 0; i < this.queryTokens.length; i++) {
            this.removeQueryToken(i);
        }

        for (const queryElement of queryArray) {
            if (queryElement.key === 'freetext') {
                this.addFTXToken(queryElement.value.key);
                continue;
            }
            // get the token
            const token = this.tokens.find(t => t.key === queryElement.key);
            if (!token) {
                continue;
            }

            this.addQueryToken(token, false);
            this.setTokenValue(queryElement.value);
        }
        if (execute) {
            this.search.next(this.queryKVP);
        }
    }

    public flipSortDirection(selectedSortOption: QueryBuilderSortOption): void {
        selectedSortOption.direction =
            selectedSortOption.direction === 'asc' ? 'desc' : 'asc';
        this.search.next(this.queryKVP);
    }
}
