<div class="container py-3">
    <div class="small text-muted mb-3 border-bottom pb-2">
        <a class="text-muted" [routerLink]="['/my-portal']">My Portal</a>
        &rsaquo;
        <a class="text-muted" [routerLink]="['/my-portal/shipments']">Shipments</a>
        &rsaquo;
        <a [routerLink]="['./']" class="font-weight-bold text-muted">History</a>
    </div>
    <div class="d-flex justify-content-between items-align-center">
        <h3 class="font-weight-normal m-0">Your shipments history</h3>
        <div class="flex-grow-0">
            <button class="btn btn-outline-secondary btn-sm" [routerLink]="['/my-portal/booking/new/choose']">
                New shipment
            </button>
        </div>
    </div>
    <ul class="list-group list-group-flush border-top mt-3">
        <ng-container *ngIf="shipments$ | async; let shipments; else loading">
            <li class="list-group list-group-item d-flex flex-row align-items-center"
                *ngFor="let shipment of shipments.data">
                <div>
                    <ng-container *ngIf="shipment.deleted_at">
                        <span class="small badge badge-info badge-sm">
                            Deleted
                        </span>
                    </ng-container>
                    <span class="small badge badge-success badge-sm" *ngIf="shipment.sys_state === 'COMPLETED'">
                        Completed
                    </span>
                    <span class="small badge badge-warning badge-sm" *ngIf="shipment.sys_state === 'CANCELLED'">
                        Cancelled
                    </span>
                </div>
                <div class="flex-grow-1 pl-2">
                    <a [routerLink]="['/my-portal/shipments/', shipment.id]"
                        *ngIf="shipment.sys_state === 'COMPLETED' || shipment.sys_state === 'CANCELLED'">{{ shipment.name }}</a>
                    <span *ngIf="shipment.deleted_at">{{ shipment.name }}</span>
                </div>
                <div>
                    <button class="btn btn-sm btn-light mr-2" *ngIf="shipment.deleted_at" [disabled]="isBusy"
                        (click)="restoreShipment(shipment.id)">
                        <fa-icon [icon]="['far', 'spinner']" pulse="true" class="mr-1"
                            *ngIf="isRestoring === shipment.id"></fa-icon>
                        Restore
                    </button>
                </div>
                <div class="">
                    <span class="text-muted">Updated: {{ shipment.updated_at | amLocal | amTimeAgo }}</span>
                </div>

            </li>
            <li class="list-group-item text-center" *ngIf="!shipments || shipments.data.length === 0">
                <h4 class="m-0 text-muted font-weight-normal">
                    <fa-icon [icon]="['far', 'folder-open']" class="mr-2" size="lg"></fa-icon>
                    <div class="mt-2">There is nothing here...</div>
                </h4>
            </li>

        </ng-container>
    </ul>
    <ng-template #loading>
        <qw-loading-indicator text="Loading history..." componentClass="m-5 text-muted">
        </qw-loading-indicator>
    </ng-template>
</div>
