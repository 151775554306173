<div class="modal-header bg-primary text-color-contrast-primary">
    <div class="lead">Calculate Totals</div>
</div>
<form [formGroup]="dimensionsForm" (submit)="apply()">
    <div class="modal-body">

        <div class="form-group">
            <select formControlName="unit" class="form-control form-control-sm w-auto">
                <option ngValue="cft">Imperial</option>
                <option ngValue="cbm">Metric</option>
            </select>
        </div>
        <div class="form-row text-muted">
            <small class="col-1">Qty</small>
            <small class="col">Length</small>
            <small class="col">Width</small>
            <small class="col">Height</small>
            <small class="col">Item Weight</small>
            <small class="col">Total Volume</small>
        </div>
        <div formArrayName="lines">
            <div class="form-row" *ngFor="let line of linesFormArray.controls; index as lineIdx;"
                [formGroupName]="lineIdx">
                <div class="form-group col-1">
                    <input type="number" class="form-control form-control-sm text-right" placeholder="Qty" min="1"
                        formControlName="quantity"
                        [ngClass]="{'is-invalid': line.get('quantity').invalid && line.get('quantity').touched}" />
                </div>
                <div class="form-group col">
                    <input type="number" class="form-control form-control-sm text-right" placeholder="Length"
                        [ngClass]="{'is-invalid': line.get('length').invalid && line.get('length').touched}" min="0.001"
                        formControlName="length" />
                </div>
                <div class="form-group col">
                    <input type="number" class="form-control form-control-sm text-right" placeholder="Width" min="0.001"
                        formControlName="width"
                        [ngClass]="{'is-invalid': line.get('width').invalid && line.get('width').touched}" />
                </div>
                <div class="form-group col">
                    <input type="number" class="form-control form-control-sm text-right" placeholder="Height"
                        min="0.001" formControlName="height"
                        [ngClass]="{'is-invalid': line.get('height').invalid && line.get('height').touched}" />
                </div>
                <div class="form-group col">
                    <div class="input-group input-group-sm">
                        <input type="number" class="form-control text-right" placeholder="Weight" min="0.01"
                            formControlName="weight"
                            [ngClass]="{'is-invalid': line.get('weight').invalid && line.get('weight').touched}" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                {{ dimensionsForm.get('unit').value === 'cbm' ? 'Kgs' : 'Lbs' | titlecase }}
                            </span>
                        </div>
                    </div>

                </div>
                <div class="form-group col">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control text-right" placeholder="Total"
                            formControlName="line_total" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                {{ dimensionsForm.get('unit').value | titlecase }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="dimensionsForm.get('lines').controls.length > 1">
                    <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeLine(lineIdx)">
                        <fa-icon [icon]="['far', 'times']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="border-top border-dark form-row" style="min-height: 3px;"></div>
        <div class="border-top border-dark total form-row pt-3">
            <div class="col-1" style="overflow:visible;">
                <button type="button" class="btn btn-sm btn-link p-0" (click)="addLine()" style="white-space:nowrap;">
                    <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>Add a line
                </button>
            </div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>

            <div class="form-group col">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control text-right" placeholder="Total" formControlName="weight" />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            {{ dimensionsForm.get('unit').value === 'cbm' ? 'Kgs' : 'Lbs' | titlecase }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group col">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control text-right" placeholder="Total" formControlName="volume" />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            {{ dimensionsForm.get('unit').value | titlecase }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="linesFormArray.controls.length > 1">
                <button class="invisible btn btn-sm btn-outline-danger">
                    <fa-icon [icon]="['far', 'times']"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Apply</button>
        <button type="button" class="btn btn-light">Cancel</button>
    </div>
</form>
