import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinArrayWithMore',
})
export class JoinArrayWithMorePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: null | string[], ...args: any[]): any {
        if (Array.isArray(value)) {
            if (value.length > args[0]) {
                return (
                    value.slice(0, args[0]).join(', ') +
                    ` and ${value.length - args[0]} more`
                );
            }

            return value.join(', ');
        }
        return value;
    }
}
