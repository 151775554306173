<div class="min-vh-100 bg-white">
    <div class="container py-3 pb-5 px-1 px-md-3">
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a [routerLink]="['/my-portal/dashboard']" class="text-muted">My Portal</a>
            &rsaquo;
            <a [routerLink]="['./']" class="text-muted font-weight-bold">Quotations</a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">Your quotations</h3>
            <div>
                <button
                        *ngIf="(permissions$ | async).includes(customerPermissionsEnum.CREATE_QUOTATIONS)"
                        [routerLink]="['/my-portal/booking-wizard']"
                        class="btn btn-primary btn-sm"
                >
                    Create<span class="d-none d-md-inline"> new quotation</span>
                </button>
            </div>
        </div>
        <ul class="list-group list-group-flush mt-3">
            <li class="list-group-item bg-light border  d-flex align-items-center">
                <div class="flex-grow-1 d-none d-md-block">
                    <qwyk-query-builder (loadSuggestions)="loadQueryBuilderSuggestions($event)"
                                        (search)="search($event)"
                                        [disabled]="queryBuilderDisabled"
                                        [fullTextSearch]="false"
                                        [loadingSuggestions]="loadingQueryBuilderSuggestions"
                                        [queries]="queryBuilderQueryTokens"
                                        [recentSearches]="recentSearches$"
                                        [sortOptions]="queryBuilderSortOptions"
                                        [suggestions]="queryBuilderSuggestions" [tokens]="queryBuilderTokens"
                                        size="sm">
                    </qwyk-query-builder>
                </div>
                <div class="pl-2">
                    <div class="custom-control custom-switch">
                        <input (change)="onIncludeSharedChanged()" [(ngModel)]="includeShared"
                               class="custom-control-input"
                               id="include_shared" type="checkbox">
                        <label class="custom-control-label" for="include_shared">Include shared</label>
                    </div>
                </div>
            </li>

            <ng-container *ngIf="quotations$ | async; let quotations;">
                <li *ngFor="let quotation of quotations"
                    [ngClass]="{'bg-info': quotation.is_shared_with}"
                    [routerLink]="['../', quotation.id]"
                    class="list-group-item list-group-item-action d-flex flex-column py-3"
                    style="cursor: pointer;">
                    <ng-container *ngIf="quotation.request; let request">
                        <div class="d-flex">
                            <div class="flex-grow-1 font-weight-bold text-dark">
                                <h6 class="mb-1">
                                    <a [routerLink]="['../', quotation.id]" class="text-dark" style="opacity: 0.9;">
                                        <span *ngIf="quotation.friendly_id">{{ quotation.friendly_id }} | </span>
                                        <span *ngIf="request.owner_reference"
                                              class="font-weight-normal">{{ request.owner_reference}}</span>
                                        <span *ngIf="!request.owner_reference" class="font-weight-normal">
                                            {{ request.transport_mode | titlecase }}
                                            <ng-container
                                                    *ngIf="request.transport_mode !== 'AIR'">{{ request.load_type | uppercase }}</ng-container>
                                            &bull;
                                            {{ request.origin.name }} to
                                            {{ request.destination.name }}
                                        </span>
                                    </a>
                                    <span *ngIf="quotation.is_shared_with" class="badge badge-pill badge-light border">
                                        Shared
                                    </span>
                                </h6>
                            </div>
                            <div>
                                <a *ngIf="quotation.open_rate_requests_count"
                                   [ngbTooltip]="quotation.open_rate_requests_count ? 'Open rate request' : 'Rate request response'"
                                   [routerLink]="['../', quotation.id]"
                                   class="text-dark mr-2">
                                    <fa-icon
                                            [fixedWidth]="true"
                                            [icon]="['fas', quotation.open_rate_requests_count ? 'question' : 'hand-holding-usd']"
                                            class="mr-1"></fa-icon>
                                </a>
                                <a [ngClass]="{'show-disabled': quotation.offers_count === 0}"
                                   [routerLink]="['../', quotation.id]"
                                   class="text-dark" ngbTooltip="Offers">
                                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'list']" class="mr-1">
                                    </fa-icon>
                                    {{ quotation.offers_count }}
                                </a>
                            </div>
                        </div>
                        <div class="mt-1 text-muted">
                            Created {{ request.created_at | amTimeAgo }}
                            &bull; <a [ngbPopover]="headerUserPopoverContent" class="text-muted"
                                      href="javascript:void(0)"
                                      placement="bottom" triggers="mouseenter:mouseleave">
                            <img
                                    [src]="'https://www.gravatar.com/avatar/' + (quotation.owner.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (quotation.owner.name | urlEncode) + '/24/097aff/ffffff/2//false/true/true'"
                                    alt="Avatar"
                                    class="rounded-circle border"
                                    style="width: 20px; height: 20px; margin-top: -4px">{{ quotation.owner.name }}
                        </a>
                            <ng-template #headerUserPopoverContent>
                                <qwyk-user-info-tooltip [user]="quotation.owner"></qwyk-user-info-tooltip>
                            </ng-template>
                            <span *ngIf="request.owner_reference">
                                &bull;
                                {{ request.transport_mode | titlecase }}
                                <ng-container
                                        *ngIf="request.transport_mode !== 'AIR'">{{ request.load_type | uppercase }}</ng-container>
                                &bull;
                                {{ request.origin.name }} to
                                {{ request.destination.name }}
                            </span>
                        </div>
                    </ng-container>
                </li>
                <li *ngIf="(loadingQuotations$ | async) === false && (!quotations || quotations.length === 0)"
                    class="list-group-item text-center text-muted border-bottom">
                    <fa-icon [icon]="['far', 'search']" size="2x"></fa-icon>
                    <h6 class="mt-2">We didn't find any quotations to display</h6>
                    <ng-container *ngIf="(permissions$ | async).includes(customerPermissionsEnum.CREATE_QUOTATIONS)">
                        <p>Adjust your search parameters, or start by creating a new quote.</p>
                        <button [routerLink]="['/my-portal/booking-wizard']" class="btn btn-outline-secondary btn-sm">
                            Create new quotation
                        </button>
                    </ng-container>
                </li>
            </ng-container>
            <li *ngIf="loadingQuotations$ | async" class="list-group-item text-center text-muted border-bottom">
                <qwyk-loading-indicator componentClass="text-muted" size="sm" text="Loading quotations...">
                </qwyk-loading-indicator>
            </li>
        </ul>
        <div *ngIf="pagination$ | async; let pagination" class="mt-3 text-center">
            <small class="text-center text-muted ">{{ pagination.from }} to {{ pagination.to }} of
                {{ pagination.total }}</small>
            <ngb-pagination (pageChange)="onPageChanged($event)" [collectionSize]="pagination.total"
                            [pageSize]="pageSize"
                            [page]="pagination.current_page" class="mt-3 d-flex justify-content-center">
            </ngb-pagination>
        </div>
    </div>
</div>
