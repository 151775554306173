import {HttpException} from "./HttpException";

export class HttpNotFoundException extends HttpException {

    readonly STATUS_CODE = "404";

    constructor(path?: string, message?: string) {
        super(message);
    }



}
