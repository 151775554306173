/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import * as saveAs from 'file-saver';
import { tap, take, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Portals } from '@qwyk/models';
import { environment } from '@qwyk/portals/environment';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

import { AppState } from './../../../../../store/app.state';
import { Shipment } from '../../../store/models/shipments.models';
import { ShipmentsService } from '../../../services/shipments.service';
import { STATUS_ICONS } from './../../../../../constants/statusMappings';
import * as shipmentActions from './../../../store/actions/shipments.actions';
import { AttachShipmentDocumentComponent } from '../attach-shipment-document/attach-shipment-document.component';

import {
    expandCollapse2,
    fadeInAnimation,
    fadeInOutAnimation,
} from './../../../../../helpers/reusableAnimations';
import * as fromShipments from '../../../store/selectors/shipments.selectors';

@Component({
    selector: 'qw-shipment',
    templateUrl: './shipment.component.html',
    styleUrls: ['./shipment.component.scss'],
    animations: [expandCollapse2, fadeInAnimation, fadeInOutAnimation],
})
export class ShipmentComponent implements OnInit, OnDestroy {
    statusIcons = STATUS_ICONS;
    focussedAttachmentId: number;
    mouseOverBookingDetails = false;
    // expandedActivityIdx: number;
    attachmentsPage = 1;
    attachmentsPageSize = 15;
    _editShipmentName = false;
    ENV = environment;

    @ViewChild('shipmentName') shipmentName;
    public shipmentId: string;
    public nameForm: FormGroup = this.fb.group({
        name: [null, [Validators.required, Validators.maxLength(64)]],
    });
    features$ = this.siteConfig.features$;
    errorLoading$ = this.store.select(fromShipments.loadingShipmentError);
    shipment$: Observable<Shipment | null> = this.store
        .select(fromShipments.selectShipment)
        .pipe(
            tap((shipment: Shipment | null) => {
                if (shipment) {
                    if (shipment.sys_state === 'DRAFT') {
                        this.router.navigate([
                            '/my-portal/booking/new',
                            shipment.id,
                        ]);
                    } else if (shipment.sys_state === 'SENDING') {
                        this.router.navigate(['..'], {
                            relativeTo: this.route,
                            queryParams: {
                                action: 'warning',
                                title: 'We are still processing this shipment',
                                message: 'Please try again in a moment.',
                                intended_destination: this.route.snapshot.url,
                            },
                        });
                    }
                    this.nameForm.get('name')?.setValue(shipment.name);
                }
            })
        );
    queryParams$ = this.route.queryParams;
    view$ = this.queryParams$.pipe(
        map(params => {
            if (!params.view) {
                return 'activity';
            }
            return params.view;
        })
    );

    quotation$: Observable<any>;
    schedule$: Observable<Portals.SavedSchedule>;
    private paramsSubscription: Subscription;
    private loadErrorSubscription: Subscription;
    downloadingAttachment: any;
    previewAttachment: any;

    constructor(
        private service: ShipmentsService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private router: Router,
        private siteConfig: SiteConfigFacade
    ) {}

    ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(params => {
            if (params.id) {
                this.shipmentId = params.id;
                this.store.dispatch(
                    shipmentActions.loadShipment({ shipmentId: params.id })
                );
            }
        });

        this.loadErrorSubscription = this.errorLoading$.subscribe(error => {
            if (error) {
                this.router.navigate([`/my-portal/error/${error.status}`], {
                    queryParams: {
                        source: this.router.url,
                        errorName: error.statusText,
                    },
                });
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(shipmentActions.reset());
        [this.paramsSubscription, this.loadErrorSubscription].forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    public focusAttachment(attachmentId: number): boolean {
        this.focussedAttachmentId = attachmentId;

        return false;
    }

    public toggleFollowShipment(): void {}

    /**
     * Shows a model component that allows the uploading and attaching of a new document.
     */
    public uploadAttachment(): void {
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParams: { view: 'documents' },
        });
        const modalRef = this.modal.open(AttachShipmentDocumentComponent, {
            centered: true,
            backdrop: 'static',
        });

        // Set the shipment ID so the modal knows what to attach the document to.
        (
            modalRef.componentInstance as AttachShipmentDocumentComponent
        ).setShipmentId(this.shipmentId);

        modalRef.result.then(
            () => {
                this.store.dispatch(
                    shipmentActions.loadShipment({
                        shipmentId: this.shipmentId,
                    })
                );
                this.store.dispatch(
                    shipmentActions.loadShipmentActivity({
                        shipmentId: this.shipmentId,
                        limit: 5,
                    })
                );
            },
            () => {}
        );
    }

    public set editShipmentName(edit: boolean) {
        if (edit !== this._editShipmentName) {
            this._editShipmentName = edit;
            if (edit) {
                setTimeout(() => {
                    const control = this.shipmentName
                        .nativeElement as HTMLInputElement;
                    control.focus();
                    control.select();
                });
            }
        }
    }

    public get editShipmentName(): boolean {
        return this._editShipmentName;
    }

    public downloadFile(shipment, attachment, preview = false): void {
        this.downloadingAttachment = attachment.id;
        if (!attachment.download_link) {
            this.service
                .getShipmentAttachmentLink(shipment.id, attachment.id)
                .pipe(take(1))
                .subscribe(
                    response => {
                        attachment = { ...attachment, download_link: response };
                        if (!preview) {
                            window.open(response, '_blank');
                        } else {
                            this.previewAttachment = {
                                ...attachment,
                                download_link: response,
                            };
                        }
                    },
                    () => {},
                    () => {
                        this.downloadingAttachment = null;
                    }
                );
        } else {
            if (!preview) {
                window.open(attachment.download_link, '_blank');
            } else {
                this.previewAttachment = attachment;
            }
        }
    }

    public downloadShipmentPdf(shipment): void {
        const subscription = this.service
            .downloadShipmentPdf(shipment.id)
            .pipe(take(1))
            .subscribe(
                response => {
                    subscription.unsubscribe();
                    saveAs(response, `shipment-${shipment.id}.pdf`);
                },
                () => {
                    subscription.unsubscribe();
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }
}
