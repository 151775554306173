import { Component } from '@angular/core';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { ui } from '@qwyk/models';
import { map } from 'rxjs/operators';
import { AlgoliaLocationsService, CustomerPermissions } from '@qwyk/core';
import { QuotationsService } from '../../services/quotations.service';
import { AuthenticationFacade } from '@qwyk/portals/authentication';
import { Observable } from 'rxjs';

@Component({
    selector: 'qwyk-quotations-list',
    templateUrl: './quotations-list.component.html',
    styleUrls: ['./quotations-list.component.scss'],
})
export class QuotationsListComponent {
    quotations$ = this.quotationsFacade.allQuotations$;
    loadingQuotations$ = this.quotationsFacade.loading$;
    loadedQuotations$ = this.quotationsFacade.loaded$;
    pagination$ = this.quotationsFacade.pagination$;

    pageSize = 25;
    searchValue = {};
    queryBuilderSuggestions = null;
    loadingQueryBuilderSuggestions = false;
    queryBuilderDisabled = false;
    queryBuilderQueryTokens: ui.QueryBuilderQuery[] = [];
    recentSearches$ = this.service.getRecentSearches();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastQuery: any = {};
    includeShared = false;
    queryBuilderTokens = [
        {
            key: 'load_type',
            label: 'Product',
            icon: ['far', 'pallet'],
            exclusive: false,
        },
        {
            key: 'transport_mode',
            label: 'Modality',
            icon: ['far', 'ship'],
            exclusive: false,
        },

        {
            key: 'origin_locode',
            label: 'Origin',
            icon: ['far', 'map-pin'],
            exclusive: true,
        },

        {
            key: 'destination_locode',
            label: 'Destination',
            icon: ['far', 'map-pin'],
            exclusive: true,
        },
    ];
    queryBuilderSortOptions: ui.QueryBuilderSortOption[] = [
        {
            key: 'created_at',
            label: 'Created',
            direction: 'desc',
            allowChangeDirection: true,
        },
        {
            key: 'updated_at',
            label: 'Last updated',
            direction: 'desc',
            allowChangeDirection: true,
        },
    ];
    public permissions$: Observable<CustomerPermissions[]> =
        this.authentication.permissions$;
    public customerPermissionsEnum = CustomerPermissions;

    constructor(
        private quotationsFacade: QuotationsFacade,
        private service: QuotationsService,
        private algoliaLocations: AlgoliaLocationsService,
        private authentication: AuthenticationFacade
    ) {}

    public search(query, page = 1) {
        this.lastQuery = query;
        const queryCopy = Object.assign({}, query);
        queryCopy.pageSize = this.pageSize;
        queryCopy.page = page;
        queryCopy.include_shared = this.includeShared;
        this.quotationsFacade.loadQuotations(queryCopy);
    }

    public onPageChanged(page) {
        this.search(this.lastQuery, page);
    }

    onIncludeSharedChanged() {
        this.search(this.lastQuery, 1);
    }

    public loadQueryBuilderSuggestions(queryKey: {
        key: string;
        query: string;
    }) {
        if (!queryKey) {
            return;
        }

        const tmSuggestions = [
            { key: 'AIR', label: 'Air' },
            { key: 'OCEAN', label: 'Ocean' },
            { key: 'TRUCK', label: 'Truck' },
        ];

        const suggestions = [
            { key: 'fcl', label: 'Full Load' },
            { key: 'lcl', label: 'Less-than Full' },
        ];

        switch (queryKey.key) {
            case 'transport_mode':
                if (queryKey.query) {
                    this.queryBuilderSuggestions = tmSuggestions.filter(e =>
                        e.label
                            .toLowerCase()
                            .startsWith(queryKey.query.toLowerCase())
                    );
                } else {
                    this.queryBuilderSuggestions = tmSuggestions;
                }
                break;
            case 'load_type':
                if (queryKey.query) {
                    this.queryBuilderSuggestions = suggestions.filter(e =>
                        e.label
                            .toLowerCase()
                            .startsWith(queryKey.query.toLowerCase())
                    );
                } else {
                    this.queryBuilderSuggestions = suggestions;
                }
                break;
            case 'origin_locode':
            case 'destination_locode':
                this.loadLocationsFromServer(queryKey.query);
                break;
            default:
                this.queryBuilderSuggestions = [];
                break;
        }
    }

    private loadLocationsFromServer(queryString) {
        if (queryString) {
            this.loadingQueryBuilderSuggestions = true;
            const subscr = this.algoliaLocations
                .getLocationSuggestions(queryString)
                .pipe(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    map((result: any[]) => {
                        return result.map(el => {
                            return { key: el.locode, label: el.display_name };
                        });
                    })
                )
                .subscribe(
                    result => {
                        this.queryBuilderSuggestions = result;
                        this.loadingQueryBuilderSuggestions = false;
                        subscr.unsubscribe();
                    },
                    () => {
                        this.queryBuilderSuggestions = [];
                        this.loadingQueryBuilderSuggestions = false;
                        subscr.unsubscribe();
                    }
                );
        }
    }
}
