<form [formGroup]="loginForm" (submit)="onLogin()" class="pt-1">
    <h5 [translate]="'portal.login-modal.login-form.title'" class="mb-3 font-weight-normal">Sign in to your account</h5>
    <div *ngIf="error$ | async; let error">
        <ng-container [ngSwitch]="error.status">
            <div *ngSwitchCase="401" role="alert" class="alert alert-danger border-danger d-flex align-items-center">
                <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
                <span>
                    <span [translate]="'portal.login-modal.login-form.invalid-credentials-prefix'">
                        The credentials you provided do not match our records, please try again, reset your password, or
                    </span>
                    <a [routerLink]="['/auth', 'register']"
                       [translate]="'portal.login-modal.login-form.invalid-credentials-signup'" target="_blank">
                        sign up for a new account.
                    </a>
                </span>
            </div>
            <span *ngSwitchCase="403">
                <div *ngIf="error.error.type === 'email-unconfirmed' && !resentConfirmation" role="alert"
                     class="alert alert-danger border-danger d-flex align-items-center">
                    <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
                    <div>
                        <span [translate]="'portal.login-modal.login-form.unverified-email-prefix'">
                        You haven't verified your email address, please check your inbox or
                        </span>
                        <span>&nbsp;</span>
                        <a (click)="onResendConfirmationEmail()"
                           [translate]="'portal.login-modal.login-form.unverified-email-resend-link'"
                           href="javascript:void(0);">resend the email</a>
                        <span>&nbsp;</span>
                        <span [translate]="'portal.login-modal.login-form.unverified-email-suffix'">
                        if you haven't received it.
                        </span>
                    </div>
                </div>
                <div *ngIf="error.error.type === 'not-allowed-on-site'" role="alert"
                    class="alert alert-danger border-danger d-flex align-items-center">
                    <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
                    <div>
                        {{ error.error.message }}
                        <span [translate]="'portal.login-modal.login-form.not-allowed-on-site-suffix'">Please contact support if you believe this is incorrect.</span>
                    </div>
                </div>
                <div *ngIf="error.error.type === 'account-unconfirmed'" role="alert"
                     class="alert alert-warning border-warning  d-flex align-items-center">
                    <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-3 text-warning"></fa-icon>
                    <div>
                        {{ error.error.message }}
                        <span [translate]="'portal.login-modal.login-form.account-unconfirmed-suffix'">
                            Once someone has confirmed it we will send an email to notify you and you will be able to login.
                        </span>
                    </div>
                </div>
            </span>
            <span *ngSwitchDefault [translate]="'portal.login-modal.login-form.generic-error'">
                Error logging you in, try again later or contact support if the problem
                persists.</span>
        </ng-container>
    </div>
    <div
            *ngIf="loginForm.controls.hasOwnProperty('email')"
            class="form-group text-left"
    >
        <label for="email">
            <span [translate]="'portal.login-modal.login-form.label-email'">Email Address</span>
            <span class="text-primary"
                [ngClass]="{'text-danger': loginForm.get('email').invalid && loginForm.get('email').touched}">
                *
            </span>
        </label>
        <input id="email" name="email" type="text" class="form-control form-control-lg" formControlName="email"
               [attr.placeholder]="'portal.login-modal.login-form.placeholder-email' | translate" autocomplete="email"
               ngbAutofocus
               [ngClass]="{'is-invalid': loginForm.get('email').invalid && loginForm.get('email').touched}"/>
        <div class="invalid-feedback">
            <div *ngIf="loginForm.get('email').errors?.required"
                 [translate]="'portal.login-modal.login-form.label-email-required'">Email Address is required
            </div>
            <div *ngIf="loginForm.get('email').errors?.email"
                 [translate]="'portal.login-modal.login-form.label-email-invalid'">Email Address must be valid
            </div>
        </div>
    </div>
    <div
            *ngIf="loginForm.controls.hasOwnProperty('username')"
            class="form-group text-left"
    >
        <label for="email">
            <span [translate]="'portal.login-modal.login-form.label-username'">Username</span>
            <span
                    [ngClass]="{
                    'text-danger':
                        loginForm.get('username').invalid &&
                        loginForm.get('username').touched
                }"
                    class="text-primary"
            >
                *
            </span>
        </label>
        <input
                [ngClass]="{
                'is-invalid':
                    loginForm.get('username').invalid &&
                    loginForm.get('username').touched
            }"
                autocomplete="liveTrackUsername"
                class="form-control form-control-lg"
                formControlName="username"
                id="username"
                name="username"
                ngbAutofocus
                [attr.placeholder]="'portal.login-modal.login-form.placeholder-username' | translate"
                type="text"
        />
        <div class="invalid-feedback">
            <div *ngIf="loginForm.get('username').errors?.required" [translate]="'portal.login-modal.login-form.label-username-required'">
                Username is required
            </div>
        </div>
    </div>
    <div class="form-group text-left">
        <label for="email">
            <span [translate]="'portal.login-modal.login-form.label-password'">Password</span>
            <span class="text-primary"
                [ngClass]="{'text-danger': loginForm.get('password').invalid && loginForm.get('password').touched}">
                *
            </span>
        </label>
        <input id="password" name="password" type="password" class="form-control form-control-lg"
               [attr.placeholder]="'portal.login-modal.login-form.placeholder-password' | translate"
               autocomplete="current-password" formControlName="password"
               [ngClass]="{'is-invalid': loginForm.get('password').invalid && loginForm.get('password').touched}"/>
        <div [translate]="'portal.login-modal.login-form.label-password-required'" class="invalid-feedback">
            Password is required
        </div>
    </div>
    <div class="form-group text-left" *ngIf="siteConfiguration.is_multi_ltm">
        <p-dropdown
            [ngClass]="{
                'is-invalid ng-dirty ng-invalid':
                    loginForm.get('ltm_id').invalid &&
                    loginForm.get('ltm_id').touched
            }"
            [options]="siteConfiguration.magaya_multi_ltm_collection"
            formControlName="ltm_id"
            optionLabel="ltm_portal_name"
            [showClear]="true"
            optionValue="network_id"
            placeholder="{{'portal.login-modal.login-form.select-tenant' | translate}}">
        </p-dropdown>
        <div
            class="invalid-feedback"
            *ngIf="loginForm.get('ltm_id').errors?.required"
            [translate]="'portal.login-modal.login-form.label-ltm-required'"
        >
            Tenant is required
        </div>
    </div>
    <div class="form-group">
        <button class="btn btn-primary btn-block" type="submit" [disabled]="loginForm.disabled">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="authenticating$ | async" class="mr-1"></fa-icon>
            <span [translate]="'portal.login-modal.login-form.button-login'">Sign in</span>
        </button>
    </div>
    <div class="text-center">
        <button class="btn btn-link text-primary btn-sm" type="button" [disabled]="loginForm.disabled"
                (click)="onGoToResetPassword()" *ngIf="!disablePasswordReset" [translate]="'portal.login-modal.login-form.button-forgot-password'">
            Don't remember your password?
        </button>
    </div>
</form>
