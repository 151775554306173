import {ExceptionBase} from "./ExceptionBase";

export abstract class HttpException extends ExceptionBase {

    public abstract readonly STATUS_CODE;

    constructor(message?: string) {
        super(message);
    }

}
