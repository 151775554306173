import { QuotationsIndexHeaderComponent } from './quotations-index-header/quotations-index-header.component';
import { QuotationsIndexSearchComponent } from './quotations-index-search/quotations-index-search.component';
import { QuotationsIndexListComponent } from './quotations-index-list/quotations-index-list.component';
import { QuotationsListItemComponent } from './quotations-list-item/quotations-list-item.component';
import { QuotationsIndexTableComponent } from './quotations-index-table/quotations-index-table.component';
import { QuotationStatusBadgeComponent } from './quotation-status-badge/quotation-status-badge.component';
import { QuotationDetailHeaderComponent } from './quotation-detail-header/quotation-detail-header.component';
import { QuotationDetailNavsComponent } from './quotation-detail-navs/quotation-detail-navs.component';
import { QuotationSummaryComponent } from './quotation-summary/quotation-summary.component';
import { QuotationPackagesListComponent } from './quotation-packages-list/quotation-packages-list.component';
import { QuotationRateRequestListComponent } from './quotation-rate-request-list/quotation-rate-request-list.component';

export * from './quotations-list/quotations-list.component';
export * from './quotation-view/quotation-view.component';
export * from './quotation-offer-list-item/quotation-offer-list-item.component';
export * from './quotation-offer-list-item-charges/quotation-offer-list-item-charges.component';
export * from './quotation-offer-list-item-map/quotation-offer-list-item-map.component';
export * from './quotation-offer-list-item-schedules/quotation-offer-list-item-schedules.component';
export * from './request-for-quotation-offer/request-for-quotation-offer.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const components: any[] = [
    QuotationsIndexHeaderComponent,
    QuotationsIndexSearchComponent,
    QuotationsIndexListComponent,
    QuotationsListItemComponent,
    QuotationsIndexTableComponent,
    QuotationStatusBadgeComponent,
    QuotationDetailHeaderComponent,
    QuotationDetailNavsComponent,
    QuotationSummaryComponent,
    QuotationPackagesListComponent,
    QuotationRateRequestListComponent,
];
