/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    Component,
    forwardRef,
    OnDestroy,
    Input,
    ComponentRef,
    ComponentFactoryResolver,
    Injector,
    ApplicationRef,
    EmbeddedViewRef,
    ViewChild,
    Output,
    EventEmitter,
    TemplateRef,
    ContentChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationTypeaheadPanelComponent } from './location-typeahead-panel.component';
import { debounceTime } from 'rxjs/operators';
import {
    LocationTypeaheadItemDirective,
    LocationTypeaheadPlaceholderDirective,
    LocationTypeaheadHintDirective,
    LocationTypeaheadNotFoundDirective,
} from './location-typeahead-templates.directive';

export const LOCATION_TYPEAHEAD_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationTypeaheadComponent),
    multi: true,
};

export interface ILocationTypeaheadValue {
    displayTitle: string;
}

@Component({
    selector: 'qw-location-typeahead',
    templateUrl: './location-typeahead.component.html',
    styleUrls: ['./location-typeahead.component.scss'],
    providers: [LOCATION_TYPEAHEAD_CONTROL_VALUE_ACCESSOR],
})
export class LocationTypeaheadComponent
    implements OnDestroy, ControlValueAccessor
{
    /**
     * Sets and holds the controls selected item.
     */
    @Input() selectedItem: any;

    /**
     * The placeholder text to display when there is no selectedItem
     */
    @Input() placeholder: string;

    /**
     * The control to append the suggestions panel to.
     */
    @Input() appendPanelTo = 'body';

    /**
     * Sizing of the control.
     */
    @Input() size: null | 'lg';

    /**
     * Whether the value of the control is considered invalid
     */
    @Input() invalid = false;

    /**
     * The selectedItem property to use as the label.
     */
    @Input() labelProperty: string;

    /**
     * Controls the debounce time of the autocomplete emitter.
     */
    // tslint:disable-next-line: no-inferrable-types
    @Input() completeDebounceTime = 200;

    @Input() readonly = false;

    /**
     * Sets whether we're currently searching for results.
     */
    @Input()
    public set searching(searching: boolean) {
        if (this.suggestionsPanel) {
            this.suggestionsPanel.instance.isSearching = searching;
        }
    }

    /**
     * Bound one-way input for the suggestion to display in the suggestions panel.
     */
    @Input()
    public set suggestions(suggestions: any[]) {
        if (this.suggestionsPanel) {
            this.suggestionsPanel.instance.suggestions = suggestions;
        }
    }

    /**
     * Emitter that gets called when the auto complete input has been changed so the client can load new suggestions.
     */
    @Output() inputChanged: EventEmitter<string> = new EventEmitter();

    /**
     * Emitter that gets called when the selected Item has been changed.
     */
    @Output() selectedItemChanged: EventEmitter<any> = new EventEmitter();

    /**
     * Child element to be used as the parent by the suggestions panel.
     */
    @ViewChild('controlButton', { static: true }) controlButton;

    /**
     * Template binding for the Item template to use in the suggestions panel.
     */
    @ContentChild(LocationTypeaheadItemDirective, {
        read: TemplateRef,
        static: true,
    })
    itemTemplate: TemplateRef<any>;

    /**
     * Template binding for the placeholder to use in the suggestions panel.
     */
    @ContentChild(LocationTypeaheadPlaceholderDirective, {
        read: TemplateRef,
        static: true,
    })
    placeholderTemplate: TemplateRef<any>;

    /**
     * Template binding for the hint to use in the suggestions panel.
     */
    @ContentChild(LocationTypeaheadHintDirective, {
        read: TemplateRef,
        static: true,
    })
    hintTemplate: TemplateRef<any>;

    /**
     * Template binding for the not found text to use in the suggestions panel.
     */
    @ContentChild(LocationTypeaheadNotFoundDirective, {
        read: TemplateRef,
        static: true,
    })
    notFoundTemplate: TemplateRef<any>;

    private onSelectedItemChanged: (_: any) => void;
    private onTouched: (_: any) => void;
    private isDisabled = false;
    private suggestionsPanel: ComponentRef<LocationTypeaheadPanelComponent>;
    private acicDebouncerSubscription: Subscription;
    private autocompleteInputChangedDebouncer: BehaviorSubject<string> =
        new BehaviorSubject('');

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef
    ) {
        // Setup a debouncer the emits the inputChanged output when the input value has been changed.
        this.acicDebouncerSubscription = this.autocompleteInputChangedDebouncer
            .pipe(debounceTime(this.completeDebounceTime))
            .subscribe(value => {
                if (value.length > 0) {
                    this.inputChanged.next(value);
                }
            });
    }

    ngOnDestroy(): void {
        if (this.acicDebouncerSubscription) {
            this.acicDebouncerSubscription.unsubscribe();
        }
    }

    /**
     * Sets the selectedItem value of the control
     * Part of ControlValueAccessor
     * @param obj the value to set
     */
    writeValue(obj: any): void {
        this.selectedItem = obj;
    }

    /**
     * Registers a change handler
     * Part of ControlValueAccessor
     * @param the handler to register
     */
    registerOnChange(fn: any): void {
        this.onSelectedItemChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Handles the dis/enabling of the control
     * Part of ControlValueAccessor
     * @param isDisabled whether to set the control as disabled
     */
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Public getter that returns whether the control has been disabled.
     */
    public get disabled() {
        return this.isDisabled;
    }

    /**
     * Input and public setter that sets the control's disabled state.
     */
    @Input()
    public set disabled(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    /**
     * Public getter for the selected item label displayed in the control based on the labelProperty.
     * @returns string the label to display
     */
    public get selectedItemLabel() {
        if (!this.selectedItem) {
            return '';
        }
        if (
            !this.labelProperty ||
            // eslint-disable-next-line no-prototype-builtins
            !this.selectedItem.hasOwnProperty(this.labelProperty)
        ) {
            return this.selectedItem.toString();
        }

        return this.selectedItem[this.labelProperty];
    }

    /**
     * Opens suggestions panel
     * @returns void
     */
    public openSuggestionsPanel(): void {
        // Ignore if the control is disabled or if a panel is already attached/open.
        if (this.isDisabled || this.suggestionsPanel) {
            return;
        }

        // Resolve the component factory and create.
        this.suggestionsPanel = this.componentFactoryResolver
            .resolveComponentFactory(LocationTypeaheadPanelComponent)
            .create(this.injector);
        const panelHtmlElement: HTMLElement = this.setupSuggestionsPanel();

        document
            .getElementsByTagName(this.appendPanelTo)[0]
            .appendChild(panelHtmlElement);
    }

    /**
     * Instantiates the suggestions panel and does setup.
     * @returns HTMLElement that is the suggestions panel
     */
    private setupSuggestionsPanel(): HTMLElement {
        const instance: LocationTypeaheadPanelComponent =
            this.suggestionsPanel.instance;

        instance.setup(
            this.onItemSelected,
            this.onAutocompleteInputChanged,
            this.controlButton,
            this.selectedItem,
            this.labelProperty,
            this.placeholder,
            this.size
        );

        instance.itemTemplate = this.itemTemplate;
        instance.placeholderTemplate = this.placeholderTemplate;
        instance.hintTemplate = this.hintTemplate;
        instance.notFoundTemplate = this.notFoundTemplate;

        // Attach the control to the DOM.
        this.appRef.attachView(this.suggestionsPanel.hostView);

        // get the created DOM element.
        const domElement = (
            this.suggestionsPanel.hostView as EmbeddedViewRef<any>
        ).rootNodes[0] as HTMLElement;

        return domElement;
    }

    /**
     * Callback function that gets invoked when the user selects an item on the suggestions panel.
     */
    private onItemSelected = (selectedItem: any | null) => {
        this.selectedItem = selectedItem;

        // Call the changed function that is part of ControlValueAccessor.
        if (this.onSelectedItemChanged) {
            this.onSelectedItemChanged(this.selectedItem);
        }

        // Invoke next on the Output eventemitter for selectedItemChanged.
        this.selectedItemChanged.next(this.selectedItem);

        // Close and destroy the suggestions panel.
        this.destroySuggestionsPanel();
    };

    /**
     * Callback function that gets called when the input is changed by the child suggestions panel.
     */
    private onAutocompleteInputChanged = (searchQuery: string) => {
        this.autocompleteInputChangedDebouncer.next(searchQuery);
    };

    /**
     * Destroys the inner suggestions panel
     */
    private destroySuggestionsPanel(): void {
        if (this.suggestionsPanel) {
            if (this.onTouched) {
                this.onTouched(true);
            }
            this.appRef.detachView(this.suggestionsPanel.hostView);
            this.suggestionsPanel.destroy();
        }
    }
}
