<ng-container *ngIf="shipment$ | async; let shipment; else loadingShipment">
    <div class="container py-3 mb-5">
        <div class="small text-muted mb-2 border-bottom pb-2">
            <a [routerLink]="['/my-portal']" class="text-muted">My Portal</a>
            &rsaquo;
            <a [routerLink]="['/my-portal/shipments']" class="text-muted">Shipments</a>
            &rsaquo;
            <a [routerLink]="['./']" class="font-weight-bold text-muted">{{ shipment.name | titlecase }}</a>
        </div>
        <div class="border-bottom d-flex mb-3 pb-2 align-items-center">
            <div *ngIf="shipment.last_event; let last_event" class="flex-grow-1">
                <span class="badge badge-secondary mr-3 font-weight-normal" style="font-size: 1em">
                    <fa-icon [icon]="['far', last_event.icon]" class="mr-2"></fa-icon>
                    {{ last_event.actual_label }}
                </span>
                <span class="text-muted">
                    <span [ngbTooltip]="last_event.actual_timestamp | amLocal | amDateFormat: 'LLL'"
                          placement="bottom">{{ last_event.actual_timestamp | amLocal | amTimeAgo }}</span>
                    <ng-container *ngIf="last_event?.user">
                        by
                        <a [ngbPopover]="headerUserPopoverContent" href="javascript:void(0)" placement="bottom"
                           triggers="mouseenter:mouseleave"> {{ last_event?.user.name }}</a>
                        <ng-template #headerUserPopoverContent>
                            <qwyk-user-info-tooltip [user]="last_event?.user"></qwyk-user-info-tooltip>
                        </ng-template>
                    </ng-container>
                </span>
            </div>
            <div class="flex-shrink-0">
                <div aria-label="Button group with nested dropdown" class="btn-group" container="body" ngbDropdown
                     placement="bottom-end" role="group">
                    <button class="btn btn-outline-secondary" ngbDropdownToggle>
                        Actions
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <button [queryParams]="{'focus': shipment.id}" [routerLink]="['../map']" ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', 'map']" class="mr-2"></fa-icon>
                            View on map
                        </button>
                        <button [queryParams]="{'name': shipment.name}" [routerLink]="['../copy', shipment.id]"
                                ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', 'copy']" class="mr-2"></fa-icon>
                            Copy shipment
                        </button>
                        <button (click)="uploadAttachment()" ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', 'paperclip']" class="mr-2"></fa-icon>
                            Attach document
                        </button>
                        <button (click)="downloadShipmentPdf(shipment)" ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', 'file-download']" class="mr-2"></fa-icon>
                            Download
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-flex flex-grow justify-content-between">
            <form [formGroup]="nameForm" class="flex-grow-1">
                <h3 class="font-weight-normal m-0">
                    <span *ngIf="!editShipmentName">
                        <ng-container *ngIf="!shipment.organization_shipment_reference">
                            {{ nameForm.get('name').value }}
                        </ng-container>
                        <ng-container *ngIf="shipment.organization_shipment_reference">
                            {{ shipment.organization_shipment_reference }}
                            <span class="font-weight-normal"> | {{ nameForm.get('name').value }}</span>
                        </ng-container>
                    </span>
                    <div *ngIf="editShipmentName" class="input-group">
                        <input #shipmentName
                               [ngClass]="{'is-invalid': nameForm.invalid && nameForm.get('name').touched}"
                               class="form-control form-control-lg"
                               formControlName="name"
                               maxlength="64"
                               placeholder="Give your shipment a convenient name or reference so you can find it easily"
                               type="text">
                        <div class="input-group-append">
                            <button [disabled]="nameForm.disabled || nameForm.invalid"
                                    class="btn btn-success text-white"
                                    ngbTooltip="Change shipment name">
                                <fa-icon [icon]="['far', 'check']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </h3>
            </form>

            <button (click)="editShipmentName = !editShipmentName" *ngIf="!editShipmentName"
                    class="btn btn-light ml-2 d-none"
                    ngbTooltip="Change shipment name">
                <fa-icon [icon]="['far', editShipmentName ? 'check': 'edit']" class="text-muted"></fa-icon>
            </button>
        </div>
        <nav class="nav tab-nav border-bottom mt-4 bg-light pt-0">
            <a [queryParams]="{'view': 'activity'}" [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'rss']" class="mr-1"></fa-icon>
                Activity
            </a>
            <a [queryParams]="{'view': 'milestones'}" [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'clock']" class="mr-1"></fa-icon>
                Milestones
            </a>
            <a *ngIf="(features$ | async)?.automated_tracking_enabled?.value && shipment.tracking_request_status === 'created'"
               [queryParams]="{'view': 'tracking'}"
               [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'radar']" class="mr-1"></fa-icon>
                Tracking
            </a>
            <a [queryParams]="{'view': 'documents'}" [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'file']" class="mr-1"></fa-icon>
                Documents
            </a>
            <a [queryParams]="{'view': 'booking'}" [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'file-signature']" class="mr-1"></fa-icon>
                Booking Details
            </a>
            <a [queryParams]="{'view': 'invoices'}" [routerLink]="['./']" class="nav-link"
               routerLinkActive="active bg-white">
                <fa-icon [icon]="['far', 'file-invoice']" class="mr-1"></fa-icon>
                Invoices
            </a>
        </nav>
        <ng-container *ngIf="(view$ | async) === 'activity'">
            <div class="m-3">
                <ng-container *ngIf="features$ | async; let features">
                    <qw-shipment-activity-list [disableComments]="features.disable_shipment_comments?.value"
                                               [shipmentId]="shipment.id"></qw-shipment-activity-list>
                    <div *ngIf="features.ise.value" class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col d-flex align-items-center">
                                    <div>
                                        <h5 class="mb-3">
                                            Want to quickly attach something, anything, to this shipment?
                                        </h5>
                                        <p>
                                            Send an email to
                                            <a [href]="'mailto:' + features.ise.ise_prefix.value + '+' + shipment.id + '@' + ENV.inboundEmailsDomain"
                                               class="bg-light text-monospace p-1 text-dark rounded"
                                               target="_blank">
                                                {{features.ise.ise_prefix.value}}+{{ shipment.id
                                                }}@{{ENV.inboundEmailsDomain}}</a>
                                            Your email will get added as a comment on this shipment and we link
                                            any PDF, Excel, or Word documents as attachments.
                                        </p>
                                        <p class="mb-0">
                                            Each shipment has a unique email address that you can use to send important
                                            information that you want to have attached, or simply reply to any
                                            notification related to a shipment. <a
                                            href="https://docs.qwykportals.com/features/shipments/emailing-shipments"
                                            target="_blank">Read more.</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-auto d-flex align-items-center">
                                    <div style="height: 160px;">
                                        <img src="https://cdn.qwyk.io/portals/assets/mailprocess.png"
                                             style="max-height: 100%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="(view$ | async) === 'milestones'">
            <div class="row mt-3">
                <div class="col-6">
                    <div class="ml-2">
                        <h5 class="m-0">Milestones</h5>
                        <small class="text-muted mb-3">Dates and times local to their locations.</small>
                        <ul class="timeline">
                            <li *ngFor="let timeitem of shipment.timeline"
                                [ngClass]="{'actual': timeitem.actual_timestamp}"
                                class="timeline-item">
                                <div class="timeline-item-inner w-100">
                                    <div
                                        [ngClass]="{'bg-success text-light border-success': timeitem.actual_timestamp, 'text-muted': !timeitem.actual_timestamp}"
                                        class="timeline-icon border">
                                        <fa-icon [fixedWidth]="true" [icon]="['far', timeitem.icon]" size="lg">
                                        </fa-icon>
                                    </div>
                                    <div [ngClass]="{'text-muted': !timeitem.actual_timestamp}"
                                         class="timeline-item-content">
                                        <ng-container *ngIf="!timeitem.actual_timestamp">
                                            <div>{{ timeitem.expected_label }}</div>
                                            <div
                                                *ngIf="timeitem.key !== 'origin.booked' && timeitem.key !== 'origin.confirmed' && timeitem.key !== 'shipment.closed'"
                                                class="small">
                                                {{ timeitem.expected_timestamp ? (timeitem.expected_timestamp |
                                                amParseZone | amDateFormat: 'L') : 'Not yet known'}}
                                                @ {{ timeitem.location?.location?.display_name || 'Location not set' }}
                                            </div>
                                            <div
                                                *ngIf="timeitem.key === 'origin.booked' || timeitem.key === 'origin.confirmed' || timeitem.key === 'shipment.closed'"
                                                class="small">
                                                {{ timeitem.expected_timestamp ? (timeitem.expected_timestamp |
                                                amParseZone | amDateFormat: 'L') : 'Not yet known'}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="timeitem.actual_timestamp">
                                            <div>{{ timeitem.actual_label }}</div>
                                            <div
                                                *ngIf="timeitem.key !== 'origin.booked' && timeitem.key !== 'origin.confirmed'&& timeitem.key !== 'shipment.closed'"
                                                class="small">
                                                {{ timeitem.actual_timestamp | amParseZone | amDateFormat: 'L' }} @
                                                {{ timeitem.location?.location?.display_name || 'Location not set' }}
                                            </div>
                                            <div
                                                *ngIf="timeitem.key === 'origin.booked' || timeitem.key === 'origin.confirmed' || timeitem.key === 'shipment.closed'"
                                                class="small">
                                                {{ timeitem.actual_timestamp ? (timeitem.actual_timestamp | amLocal |
                                                amDateFormat: 'L') : 'Not yet known'}}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">

                    <h5 class=" m-0">Schedule</h5>
                    <small class="text-muted mb-3">Dates local to their locations.</small>

                    <table class="table table-sm table-borderless">
                        <tbody>
                        <tr>
                            <td>
                                <fa-icon [fixedWidth]="true" [icon]="['far', 'ship']" class="mr-2"></fa-icon>
                            </td>
                            <td colspan="2">{{ shipment.data.vessel }} {{ shipment.data.voyage }}</td>
                        </tr>
                        <tr>
                            <td>
                                <fa-icon [fixedWidth]="true" [icon]="['far', 'anchor']" class="mr-2"></fa-icon>
                            </td>
                            <td>
                                {{ shipment.data.port_of_loading?.display_name }}
                            </td>
                            <td>
                                {{ shipment.data.etd | amParseZone | amDateFormat:'LL' }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <fa-icon [fixedWidth]="true" [icon]="['far', 'anchor']" class="mr-2"></fa-icon>
                            </td>
                            <td>
                                {{ shipment.data.port_of_discharge?.display_name }}
                            </td>
                            <td>
                                {{ shipment.data.eta | amParseZone | amDateFormat:'LL' }}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h5 class="mt-3">References</h5>
                    <table class="table table-sm table-borderless">
                        <tbody>
                        <tr>
                            <td class="text-muted">MBL #:</td>
                            <td>{{ shipment.data.mbl_number }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">HBL #:</td>
                            <td>{{ shipment.data.hbl_number }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Booking #:</td>
                            <td>{{ shipment.organization_shipment_reference }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Shipper #:</td>
                            <td>{{ shipment.data.shipper_reference }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Consignee #:</td>
                            <td>{{ shipment.data.consignee_reference }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Notify #:</td>
                            <td>{{ shipment.data.notify_reference }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Forwarder #:</td>
                            <td>{{ shipment.data.forwarder_reference }}</td>
                        </tr>
                        <tr>
                            <td class="text-muted">Pricing #:</td>
                            <td>{{ shipment.data.pricing_reference }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(view$ | async) === 'documents'">
            <ul class="list-group list-group-flush mt-3">
                <ng-container
                    *ngFor="let attachment of (shipment.attachments | slice:(attachmentsPage-1)*attachmentsPageSize:(attachmentsPage-1)*attachmentsPageSize+attachmentsPageSize)">
                    <li [@fadeIn]
                        [ngClass]="{'bg-success-light': focussedAttachmentId === attachment.id || (queryParams$ | async).focus === attachment.id}"
                        class="list-group-item list-group-item-action d-flex align-items-center border-0"
                        id="attachment{{attachment.id}}">
                        <fa-icon [icon]="['far', attachment.extension | fileExtensionIcon]" class="text-muted mr-3"
                                 size="lg">
                        </fa-icon>
                        <div class="d-flex flex-column flex-grow-1">
                            <h6 class="m-0">{{ attachment.filename }}</h6>
                            <p class="m-0">{{ attachment.description }}</p>
                            <div class="small text-muted">
                                {{ attachment.created_at | amLocal | amTimeAgo }} by
                                {{ attachment.creator.email }}
                            </div>
                        </div>
                        <div class="flex-shrink-0">
                            <button (click)="downloadFile(shipment, attachment, true)"
                                    *ngIf="attachment.extension === 'pdf'"
                                    [disabled]="downloadingAttachment === attachment.id" class="btn btn-light mr-2"
                                    ngbTooltip="View file">
                                <fa-icon
                                    [icon]="['far', downloadingAttachment === attachment.id ? 'spinner' : 'search']"
                                    [pulse]="downloadingAttachment === attachment.id">
                                </fa-icon>
                            </button>
                            <button (click)="downloadFile(shipment, attachment)"
                                    [disabled]="downloadingAttachment === attachment.id"
                                    class="btn btn-light" ngbTooltip="Download file">
                                <fa-icon
                                    [icon]="['far', downloadingAttachment === attachment.id ? 'spinner' : 'cloud-download']"
                                    [pulse]="downloadingAttachment === attachment.id"></fa-icon>
                            </button>
                        </div>
                    </li>
                </ng-container>
                <li *ngIf="shipment.attachments.length === 0" class="list-group-item text-muted border-0 pl-0">
                    <p>This shipment doesn't have any attached documents yet.</p>
                    <button (click)="uploadAttachment()" class="btn btn-secondary btn-sm">
                        <fa-icon [icon]="['far', 'paperclip']" class="mr-2"></fa-icon>
                        Attach new document
                    </button>
                </li>
            </ul>
            <div class="d-flex justify-content-between align-items-top pl-3 mt-3 pt-3 border-top">
                <div>
                    <ngb-pagination *ngIf="shipment.attachments.length > attachmentsPageSize" [(page)]="attachmentsPage"
                                    [collectionSize]="shipment.attachments.length" [pageSize]="attachmentsPageSize"
                                    size="sm">
                    </ngb-pagination>
                </div>
                <div>
                    <button (click)="uploadAttachment()" class="btn btn-outline-primary btn-sm mr-3">
                        <fa-icon [icon]="['far', 'cloud-upload']" class="mr-2"></fa-icon>
                        Upload document
                    </button>
                </div>
            </div>

        </ng-container>
        <ng-container *ngIf="(view$ | async) === 'booking'">
            <div class="mt-3">

                <div class="row">
                    <div class="col-12">
                        <div *ngIf="shipment.data; let data" class="card mb-4 shadow-sm">
                            <div class="card-header bg-white border-bottom-0">
                                <h6 class="card-title mb-0">Parties</h6>
                            </div>
                            <ul #partiesNav="ngbNav" activeId="shipper" class="nav-tabs" ngbNav>
                                <li *ngFor="let gr of ['pickup', 'shipper', 'consignee', 'notify', 'delivery']"
                                    [disabled]="(gr === 'pickup' && data.service_type[0] !== 'd') || (gr === 'delivery' && data.service_type[data.service_type.length-1] !== 'r')"
                                    [ngbNavItem]="gr">
                                    <a ngbNavLink> {{ gr | titlecase }}</a>
                                    <ng-template ngbNavContent>
                                        <div *ngIf="data[gr]; let party" [ngClass]="{'border-left': grIdx > 0}"
                                             class="p-3">
                                            <div *ngIf="party.name">{{ party.name }}</div>
                                            <div *ngIf="party.address_1">{{ party.address_1}}</div>
                                            <div *ngIf="party.address_2">{{ party.address_2}}</div>
                                            <div *ngIf="party.city || party.state || party.postal_code">{{ party.city
                                                }},
                                                {{party.state }} {{ party.postal_code }}</div>
                                            <div *ngIf="party.country_code">{{ party.country || party.country_code }}
                                            </div>
                                            <div *ngIf="party.contact_name">{{ party.contact_name}}</div>
                                            <div *ngIf="party.phone_number">{{ party.phone_number}}</div>
                                            <div *ngIf="party.email_address">{{ party.email_address}}</div>
                                            <div *ngIf="party.reference">{{ party.reference}}</div>
                                            <div *ngIf="party.remarks">{{ party.remarks}}</div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="partiesNav"></div>
                        </div>
                    </div>
                    <div class="col">
                        <div *ngIf="shipment.data; let data" class="card mb-4 shadow-sm">
                            <div class="card-header bg-white">
                                <h6 class="card-title mb-0">Cargo</h6>
                            </div>
                            <div class="card-body py-0 px-2">
                                <table class="table mb-0 table-borderless">
                                    <tbody>
                                    <ng-container *ngFor="let package of data.packages">
                                        <tr>
                                            <!-- {{ package | json }} -->
                                            <td>{{ package.quantity }}</td>
                                            <td>{{ package.packaging?.description }}
                                            </td>
                                            <td [innerHTML]="package.description | lineBreaks"></td>
                                            <td class="text-right">
                                                {{ package.weight | number:'1.2-2' }}
                                                {{ package.uom === 'metric' ? 'Kgs' : 'Lbs' }}
                                            </td>
                                            <td class="text-right">
                                                {{ package.volume | number:'1.3-3' }}
                                                {{ package.uom === 'metric' ? 'Cbm' : 'Cft' }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.po_number || package.po_line" class="cargo-extra small">
                                            <th></th>
                                            <th>P/O:</th>
                                            <td colspan="3">{{ package.po_number }} {{ package.po_line }}</td>
                                        </tr>
                                        <tr *ngIf="package.product_id || package.product_sku || package.product_name"
                                            class="cargo-extra small">
                                            <th></th>
                                            <th>Product:</th>
                                            <td colspan="3">{{ package.product_id }} {{ package.product_sku }}
                                                {{ package.product_name }}</td>
                                        </tr>
                                        <tr *ngIf="package.hs_code" class="cargo-extra small">
                                            <th></th>
                                            <th>H/S Code:</th>
                                            <td colspan="3">{{ package.hs_code }}</td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="shipment.data; let data;">
                        <div *ngIf="data.containers && data.containers.length > 0" class="col-4">
                            <div class="card mb-4 shadow-sm">
                                <div class="card-header bg-white">
                                    <h6 class="card-title mb-0">Containers</h6>
                                </div>
                                <div class="card-body py-0 px-2">
                                    <table class="table mb-0 table-borderless">
                                        <tbody>
                                        <tr *ngFor="let container of data.containers">
                                            <td>{{ container.container_number }}</td>
                                            <td>{{ container.iso6346_code }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12">
                        <div *ngIf="shipment.data; let data" class="card mb-4 shadow-sm">
                            <div class="card-header bg-white">
                                <h6 class="card-title mb-0">Terms and remarks</h6>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <div class="text-muted head-label">INCO Term</div>
                                        <p>{{ data.inco_term || '-' }} {{ data.inco_term_place }}</p>
                                        <div class="text-muted head-label">Cargo ready on</div>
                                        <p>{{ data.cargo_ready | date }}</p>
                                    </div>
                                    <div class="col">
                                        <div class="text-muted head-label">Payment</div>
                                        <p>{{ (data.payment_terms | titlecase) || '-' }}</p>
                                        <div class="text-muted head-label">Remarks</div>
                                        <p [innerHTML]="data.remarks | lineBreaks"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- FIXME: <ng-container *ngIf="shipment.simpliship_bid_id">
                    <h5 class="mt-5 pl-2">Quoted rate</h5>
                    <qw-simpliship-quote-result-item [quoteItem]="{ID: shipment.simpliship_bid_id}" [canBook]="false"
                        [canViewShipment]="false" [showTags]="false" [showFullQuoteButton]="true">

                    </qw-simpliship-quote-result-item>
                </ng-container> -->
                <ng-container *ngIf="schedule$ | async; let schedule">
                    <h5 class="mt-5 pl-2">Schedule (as booked)</h5>
                    <qwyk-schedule-list-item [hasBookLinkAction]="false" [schedule]="schedule">
                    </qwyk-schedule-list-item>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="(view$ | async) === 'invoices'">
            <qwyk-related-invoices-list
                    [entity]="{invoicable_type: 'App\\Domain\\Shipment\\Models\\Shipment', invoicable_id: shipment.id}">

            </qwyk-related-invoices-list>
        </ng-container>
        <ng-container *ngIf="(view$ | async) === 'tracking'">
            <div class="mt-3">
                <qwyk-shipment-tracking-events-view
                    [shipment]="shipment"
                ></qwyk-shipment-tracking-events-view>
            </div>
            <div class="mt-3">
                <qwyk-shipment-containers-view
                    [shipment]="shipment"
                ></qwyk-shipment-containers-view>
            </div>
        </ng-container>
    </div>
</ng-container>
<div *ngIf="previewAttachment" [@fadeInOut] class="attachment-preview rounded border bg-white">
    <div class="h-100 d-flex flex-column">
        <div class="modal-header border-bottom-0 px-4">
            <h4 class="modal-title" id="modal-basic-title">{{ previewAttachment.filename }}</h4>
            <button (click)="previewAttachment = null" aria-label="Close" class="close" type="button">
                <fa-icon [icon]="['far', 'times']"></fa-icon>
            </button>
        </div>
        <div class="modal-body p-0">
            <object [attr.data]="previewAttachment.download_link | bypassSecurityTrustResourceUrl"
                    height="100%" type="application/pdf" width="100%">
                <p>Your browser does not support PDFs.
                    <a [attr.href]="previewAttachment.download_link">Download the PDF</a>.
                </p>
            </object>
        </div>
    </div>
</div>
<ng-template #loadingShipment>
    <div [@fadeIn] class="container">
        <qw-loading-indicator componentClass="text-muted m-5" text="Loading shipment..."></qw-loading-indicator>
    </div>
</ng-template>
