/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { ShipmentContainersFacade } from '@qwyk/shared-stores/shipment-containers';

@Component({
    selector: 'qwyk-shipment-containers-view',
    templateUrl: './shipment-containers-view.component.html',
    styleUrls: ['./shipment-containers-view.component.scss'],
})
export class ShipmentContainersViewComponent {
    shipmentContainers$ = this.shipmentContainers.allShipmentContainers$;
    loading$ = this.shipmentContainers.loading$;

    constructor(private shipmentContainers: ShipmentContainersFacade) {}

    private _shipment: any;

    public get shipment(): any {
        return this._shipment;
    }

    @Input()
    public set shipment(shipment: any) {
        this._shipment = shipment;
        this.shipmentContainers.init(shipment);
    }
}
