import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ShipmentsService } from './../../../services/shipments.service';

@Component({
    selector: 'qw-shipments-history',
    templateUrl: './shipments-history.component.html',
    styleUrls: ['./shipments-history.component.scss'],
})
export class ShipmentsHistoryComponent {
    loadingShipments = true;
    isRestoring = null;
    isBusy = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shipments$: Observable<any[]> = this.service
        .getShipments(
            null,
            null,
            { key: 'created_at', direction: 'desc' },
            false,
            true
        )
        .pipe(tap(() => (this.loadingShipments = false)));

    constructor(
        private service: ShipmentsService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    public restoreShipment(shipmentId) {
        this.isRestoring = shipmentId;
        this.isBusy = true;

        this.service.restoreDeletedBooking(shipmentId).subscribe(
            result => {
                this.isRestoring = null;
                this.isBusy = false;
                if (
                    result.sys_state === 'DRAFT' ||
                    result.sys_state === 'SENDING_FAILED'
                ) {
                    this.router.navigate([
                        '/my-portal/booking/new/',
                        result.id,
                    ]);
                } else {
                    this.router.navigate(['/my-portal/shipments/', result.id]);
                }
            },
            error => {
                this.toastr.error(
                    'Please try again or contact support if the problem persists',
                    'We could not restore your booking'
                );
                this.isRestoring = null;
                this.isBusy = false;
                throw error;
            }
        );
    }
}
