<div class="modal-body">
    <div class="row">
        <div class="col-4">
            <ng-container *ngIf="datasets$ | async; let datasets">
                <ul class="list-group list-group-flush">
                    <ng-container *ngFor="let ds of datasets.data; index as idx">
                        <div *ngIf="idx === 0 || datasets.data[idx - 1].group !== ds.group" class="text-muted">
                            {{ ds.group }}
                        </div>
                        <li class="list-group-item list-group-item-action" (click)="selectSet(idx, ds)"
                            [ngClass]="{'bg-primary text-white': idx === selectedSetIdx}">{{idx}} {{ ds.name }}</li>
                    </ng-container>
                </ul>
                {{ selectedSetIdx }}
            </ng-container>
        </div>
        <div class="col-8">
            <ng-container *ngIf="selectedSet">
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item list-group-item-action d-flex flex-row align-items-center"
                        [ngClass]="{'bg-primary text-white': idx === selectedWidgetIdx}"
                        *ngFor="let w of selectedSet.availableWidgets; index as idx;" (click)="selectWidget(idx, w)">
                        <fa-icon [icon]="['fad', w.icon]" class="text-primary mr-2" size="2x" fixedWidth="true">
                        </fa-icon>
                        <div>{{ w.name }}</div>
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
</div>
