export const DEFAULT_MAP_OPTIONS = {
    center: { lat: 0, lng: 0 },
    zoom: 2,
    mapTypeId: 'satellite',
    disableDefaultUI: true,
};

export interface SimpleMapMarker {
    label?: string;
    title: string;
    position: google.maps.LatLngLiteral;
}

export interface PolygonOptions {
    paths?: google.maps.LatLngLiteral[];
    geodesic?: boolean;
    strokeOpacity?: number;
    strokeWeight?: number;
    strokeColor?: string;
}

export function generateSimpleMarker(
    marker: SimpleMapMarker
): google.maps.Marker {
    return new google.maps.Marker({
        label: marker.label,
        position: marker.position,
        title: marker.label,
    });
}

export function generatePolylineLayer(
    points: SimpleMapMarker[],
    displayPoints = true,
    options?: google.maps.PolylineOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, google.maps.LatLngBounds] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pathLayer: any[] = [];
    const bounds = new google.maps.LatLngBounds();
    const path: google.maps.LatLngLiteral[] = [];

    for (const point of points) {
        path.push(point.position);
        if (displayPoints) {
            pathLayer.push(generateSimpleMarker(point));
        }
        bounds.extend(point.position);
    }

    let polygonOptions: google.maps.PolylineOptions = {
        path,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        strokeColor: '#ffffff',
        geodesic: true,
    };

    if (options) {
        polygonOptions = { ...polygonOptions, ...options };
    }

    pathLayer.push(new google.maps.Polyline(polygonOptions));

    return [pathLayer, bounds];
}

export function generatePolygonLayer(
    points: SimpleMapMarker[],
    displayPoints = true,
    options?: PolygonOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, google.maps.LatLngBounds] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pathLayer: any[] = [];
    const bounds = new google.maps.LatLngBounds();
    const paths: google.maps.LatLngLiteral[] = [];

    for (const point of points) {
        paths.push(point.position);
        if (displayPoints) {
            pathLayer.push(generateSimpleMarker(point));
        }
        bounds.extend(point.position);
    }

    let polygonOptions: PolygonOptions = {
        paths,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        strokeColor: '#ffffff',
        geodesic: true,
    };

    if (options) {
        polygonOptions = { ...polygonOptions, ...options };
    }

    pathLayer.push(new google.maps.Polygon(polygonOptions));

    return [pathLayer, bounds];
}

export function decimalToDMS(decimal): string {
    const absolute = Math.abs(decimal);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.round((minutesNotTruncated - minutes) * 6000) / 100;
    return `${degrees}°${minutes}'${seconds}"`;
}

export function convertDecimalToDMS({ lat, lng }): string {
    const latitude = decimalToDMS(lat);
    const latitudeCardinal = lat >= 0 ? 'N' : 'S';

    const longitude = decimalToDMS(lng);
    const longitudeCardinal = lng >= 0 ? 'E' : 'W';

    return `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`;
}
