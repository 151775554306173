import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';

@Pipe({
    name: 'productIcon',
})
export class ProductIconPipe implements PipeTransform {
    private readonly _icons: { [index: string]: string } =
        Constants.PRODUCT_ICONS;

    transform(value: string): string {
        return this._icons[value];
    }
}
