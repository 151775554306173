/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    ElementRef,
    OnDestroy,
    AfterContentInit,
    ViewChild,
    Renderer2,
    HostListener,
    TemplateRef,
} from '@angular/core';
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';

@Component({
    selector: 'qw-location-typeahead-panel',
    templateUrl: './location-typeahead-panel.component.html',
    styleUrls: ['./location-typeahead-panel.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('*', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(200)]),
            transition(':leave', [style({ opacity: 1 }), animate(200)]),
        ]),
    ],
})
export class LocationTypeaheadPanelComponent
    implements OnDestroy, AfterContentInit
{
    /**
     * Provides access to the root element of the panel control.
     */
    @ViewChild('rootElement', { static: true }) rootElement;

    /**
     * Provides access to the search input control.
     */
    @ViewChild('searchInput', { static: true }) searchInput;

    /**
     * Optional item template for the suggestions list.
     */
    public itemTemplate: TemplateRef<any>;

    /**
     * Optional template for the placeholder that is shown when an selected item is attached but no suggestions have been populated.
     */
    public placeholderTemplate: TemplateRef<any>;

    /**
     * Optional template for the hint that is displayed.
     */
    public hintTemplate: TemplateRef<any>;

    /**
     * Optional template for the not found text that is displayed.
     */
    public notFoundTemplate: TemplateRef<any>;

    /**
     * The parent this control has been attached to.
     */
    private parent: ElementRef;

    /**
     * The selectedItem property to use as the display label.
     */
    private labelProperty: string;

    /**
     * Min width in pixels of location typeahead panel component.
     */
    private minWidthPx = 250;

    /**
     * Suggestions which can be passed in by the parent.
     */
    public suggestions: any[];

    /** Controls the searching state. */
    public isSearching = false;

    /**
     * The currently selected item by the user.
     */
    public selectedItem: any;

    /**
     * The placeholder text to use on the input control.
     */
    public inputPlaceholder: string;

    /**
     * Size property of the control.
     */
    public size: null | 'lg';

    /**
     * Callback to invoke when a new item has been selected.
     */
    private onItemSelected: null | ((selectedItem: any) => void);

    /**
     * Callback to invoke when the user has changed the input text and we want the parent to load new suggestions.
     */
    private onAutocompleteInputChanged: null | ((search: string) => void);

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngOnDestroy(): void {
        this.onItemSelected = null;
        this.onAutocompleteInputChanged = null;
    }

    ngAfterContentInit(): void {
        // After the content has been initialized we set the position.
        this.setPanelSizeAndPosition();

        // Use a timeout to asynchronously focus and select the input.
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
            this.searchInput.nativeElement.select();
        });
    }

    /**
     * Handles injection and external setup of the control
     * @param itemSelectedCallback the function to call when an item has been selected.
     * @param autocompleteInputChangedCallback the function to call when the user changes the control input.
     * @param parent the parent this panel is attached to
     * @param selectedItem the currently selected item
     * @param labelProperty the property to use at the item label in the control.
     * @param [placeholder] Optional placeholder for this input control.
     * @param [size] Optional sizing (lg)
     */
    public setup(
        itemSelectedCallback: (selectedItem: any) => void,
        autocompleteInputChangedCallback: (search: string) => void,
        parent,
        selectedItem: any,
        labelProperty,
        placeholder = '',
        size: null | 'lg' = null
    ): void {
        this.onItemSelected = itemSelectedCallback;
        this.onAutocompleteInputChanged = autocompleteInputChangedCallback;

        this.parent = parent;
        this.selectedItem = selectedItem;
        this.labelProperty = labelProperty;

        this.size = size;
        this.inputPlaceholder = placeholder;
    }

    /**
     * Handles any of the confirmation keys being used on the control
     * @param e The keyboard event
     */
    public onConfirmKeys(e: KeyboardEvent) {
        let selectedItem = null;

        // If we have suggestions shown we pick the first item from that list to set as the new selected item.s
        if (this.suggestions && this.suggestions.length > 0) {
            selectedItem = this.suggestions[0];
        }

        this.itemSelected(selectedItem);

        if (e) {
            e.preventDefault();
        }
    }

    /**
     * Calls the function on the parent that handles the autocomplete input being changed.
     * @param e the keyboard event
     */
    autocompleteInputChanged(e: KeyboardEvent) {
        if (this.onAutocompleteInputChanged) {
            this.onAutocompleteInputChanged(
                (e.target as HTMLInputElement).value
            );
        }
    }

    /**
     * Takes an optional new item to select and then calls the itemSelected function passed in by the parent.
     * @param item the item tha thas been selected.
     */
    public itemSelected(item: any | null) {
        if (item) {
            this.selectedItem = item;
        }
        this.onItemSelected && this.onItemSelected(this.selectedItem);
    }

    /**
     * Public getter for the selected item label displayed in the control based on the labelProperty.
     * @returns string the label to display
     */
    public get selectedItemLabel(): string {
        if (!this.selectedItem) {
            return '';
        }
        if (
            !this.labelProperty ||
            // eslint-disable-next-line no-prototype-builtins
            !this.selectedItem.hasOwnProperty(this.labelProperty)
        ) {
            return this.selectedItem.toString();
        }

        return this.selectedItem[this.labelProperty];
    }

    /**
     * Calculates the position of the panel relative to the parent's bounding rect.
     * @returns position (top, left, width, maxHeight)
     */
    private calculatePosition(): {
        top: number;
        left: number;
        width: number;
        maxHeight: number;
    } {
        const boundingRect = (
            this.parent.nativeElement as HTMLElement
        ).getBoundingClientRect();

        return {
            top: boundingRect.top,
            left: boundingRect.left,
            width: boundingRect.width,
            maxHeight: document.defaultView
                ? document.defaultView.innerHeight - boundingRect.top
                : 0,
        };
    }

    /**
     * Sets panel size and position to values calculated relative to parent control.
     */
    private setPanelSizeAndPosition(): void {
        const position = this.calculatePosition();

        // Render the style according to the parent position.
        this.renderer.setStyle(this.elRef.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.elRef.nativeElement, 'z-index', '1000');
        [
            { prop: 'top', val: position.top },
            { prop: 'left', val: position.left },
            { prop: 'width', val: position.width },
            { prop: 'max-height', val: position.maxHeight },
            { prop: 'height', val: position.maxHeight },
            { prop: 'min-width', val: this.minWidthPx },
        ].forEach(styleSet => {
            this.renderer.setStyle(
                this.elRef.nativeElement,
                styleSet.prop,
                `${styleSet.val}px`
            );
        });
    }

    /**
     * Handles closing the panel when a click outside of itself or its pareent has been detected.
     * @param e The MouseEvent to check against
     */
    @HostListener('document:click', ['$event'])
    private onClickOutside(e: MouseEvent): void {
        if (
            !this.rootElement.nativeElement.contains(e.target) &&
            !this.parent.nativeElement.contains(e.target)
        ) {
            // If the mouseevent is not within the element or its parent's bound, we close the panel.
            this.itemSelected(null);
        }
    }

    /**
     * Handles repositioning this panel when the document is scrolled.
     * @param e the scroll event
     */
    @HostListener('document:scroll')
    private onDocumentScroll(): void {
        const bounding = (
            this.parent.nativeElement as HTMLElement
        ).getBoundingClientRect();
        this.renderer.setStyle(
            this.elRef.nativeElement,
            'top',
            `${bounding.top}px`
        );
    }

    /**
     * Handles the window getting resized and consequently calculating the panel position.
     * @param e the resize event
     */
    @HostListener('window:resize')
    private onWindowResize(): void {
        this.setPanelSizeAndPosition();
    }
}
