export const SHIPMENT_EVENT_TYPES = [
    {
        key: 'origin.booked',
        name: 'hub.shipment.search.filters.milestone.origin.booked',
        description:
            'hub.shipment.search.filters.milestone.origin.booked-description',
    },
    {
        key: 'origin.confirmed',
        name: 'hub.shipment.search.filters.milestone.origin.confirmed',
        description:
            'hub.shipment.search.filters.milestone.origin.confirmed-description',
    },
    {
        key: 'origin.received',
        name: 'hub.shipment.search.filters.milestone.origin.received',
        description:
            'hub.shipment.search.filters.milestone.origin.received-description',
    },
    {
        key: 'origin.emptyout',
        name: 'hub.shipment.search.filters.milestone.origin.emptyout',
        description: null,
    },
    {
        key: 'origin.loaded',
        name: 'hub.shipment.search.filters.milestone.origin.loaded',
        description:
            'hub.shipment.search.filters.milestone.origin.loaded-description',
    },
    {
        key: 'origin.fullin',
        name: 'hub.shipment.search.filters.milestone.origin.fullin',
        description: null,
    },
    {
        key: 'origin.customs',
        name: 'hub.shipment.search.filters.milestone.origin.customs',
        description:
            'hub.shipment.search.filters.milestone.origin.customs-description',
    },
    {
        key: 'origin.documents',
        name: 'hub.shipment.search.filters.milestone.origin.documents',
        description: null,
    },
    {
        key: 'origin.invoice',
        name: 'hub.shipment.search.filters.milestone.origin.invoice',
        description: null,
    },
    {
        key: 'origin.departed',
        name: 'hub.shipment.search.filters.milestone.origin.departed',
        description:
            'hub.shipment.search.filters.milestone.origin.departed-description',
    },
    {
        key: 'hub1.arrived',
        name: 'hub.shipment.search.filters.milestone.hub1.arrived',
        description: null,
    },
    {
        key: 'hub1.departed',
        name: 'hub.shipment.search.filters.milestone.hub1.departed',
        description: null,
    },
    {
        key: 'hub2.arrived',
        name: 'hub.shipment.search.filters.milestone.hub2.arrived',
        description: null,
    },
    {
        key: 'hub2.departed',
        name: 'hub.shipment.search.filters.milestone.hub2.departed',
        description: null,
    },
    {
        key: 'hub3.arrived',
        name: 'hub.shipment.search.filters.milestone.hub3.arrived',
        description: null,
    },
    {
        key: 'hub3.departed',
        name: 'hub.shipment.search.filters.milestone.hub3.departed',
        description: null,
    },
    {
        key: 'hub4.arrived',
        name: 'hub.shipment.search.filters.milestone.hub4.arrived',
        description: null,
    },
    {
        key: 'hub4.departed',
        name: 'hub.shipment.search.filters.milestone.hub4.departed',
        description: null,
    },
    {
        key: 'hub5.arrived',
        name: 'hub.shipment.search.filters.milestone.hub5.arrived',
        description: null,
    },
    {
        key: 'hub5.departed',
        name: 'hub.shipment.search.filters.milestone.hub5.departed',
        description: null,
    },
    {
        key: 'hub6.arrived',
        name: 'hub.shipment.search.filters.milestone.hub6.arrived',
        description: null,
    },
    {
        key: 'hub6.departed',
        name: 'hub.shipment.search.filters.milestone.hub6.departed',
        description: null,
    },
    {
        key: 'hub7.arrived',
        name: 'hub.shipment.search.filters.milestone.hub7.arrived',
        description: null,
    },
    {
        key: 'hub7.departed',
        name: 'hub.shipment.search.filters.milestone.hub7.departed',
        description: null,
    },
    {
        key: 'hub8.arrived',
        name: 'hub.shipment.search.filters.milestone.hub8.arrived',
        description: null,
    },
    {
        key: 'hub8.departed',
        name: 'hub.shipment.search.filters.milestone.hub8.departed',
        description: null,
    },
    {
        key: 'hub9.arrived',
        name: 'hub.shipment.search.filters.milestone.hub9.arrived',
        description: null,
    },
    {
        key: 'hub9.departed',
        name: 'hub.shipment.search.filters.milestone.hub9.departed',
        description: null,
    },
    {
        key: 'hub10.arrived',
        name: 'hub.shipment.search.filters.milestone.hub10.arrived',
        description: null,
    },
    {
        key: 'hub10.departed',
        name: 'hub.shipment.search.filters.milestone.hub10.departed',
        description: null,
    },
    {
        key: 'hub11.arrived',
        name: 'hub.shipment.search.filters.milestone.hub11.arrived',
        description: null,
    },
    {
        key: 'hub11.departed',
        name: 'hub.shipment.search.filters.milestone.hub11.departed',
        description: null,
    },
    {
        key: 'hub12.arrived',
        name: 'hub.shipment.search.filters.milestone.hub12.arrived',
        description: null,
    },
    {
        key: 'hub12.departed',
        name: 'hub.shipment.search.filters.milestone.hub12.departed',
        description: null,
    },
    {
        key: 'hub13.arrived',
        name: 'hub.shipment.search.filters.milestone.hub13.arrived',
        description: null,
    },
    {
        key: 'hub13.departed',
        name: 'hub.shipment.search.filters.milestone.hub13.departed',
        description: null,
    },
    {
        key: 'hub14.arrived',
        name: 'hub.shipment.search.filters.milestone.hub14.arrived',
        description: null,
    },
    {
        key: 'hub14.departed',
        name: 'hub.shipment.search.filters.milestone.hub14.departed',
        description: null,
    },
    {
        key: 'hub15.arrived',
        name: 'hub.shipment.search.filters.milestone.hub15.arrived',
        description: null,
    },
    {
        key: 'hub15.departed',
        name: 'hub.shipment.search.filters.milestone.hub15.departed',
        description: null,
    },
    {
        key: 'hub16.arrived',
        name: 'hub.shipment.search.filters.milestone.hub16.arrived',
        description: null,
    },
    {
        key: 'hub16.departed',
        name: 'hub.shipment.search.filters.milestone.hub16.departed',
        description: null,
    },
    {
        key: 'hub17.arrived',
        name: 'hub.shipment.search.filters.milestone.hub17.arrived',
        description: null,
    },
    {
        key: 'hub17.departed',
        name: 'hub.shipment.search.filters.milestone.hub17.departed',
        description: null,
    },
    {
        key: 'hub18.arrived',
        name: 'hub.shipment.search.filters.milestone.hub18.arrived',
        description: null,
    },
    {
        key: 'hub18.departed',
        name: 'hub.shipment.search.filters.milestone.hub18.departed',
        description: null,
    },
    {
        key: 'hub19.arrived',
        name: 'hub.shipment.search.filters.milestone.hub19.arrived',
        description: null,
    },
    {
        key: 'hub19.departed',
        name: 'hub.shipment.search.filters.milestone.hub19.departed',
        description: null,
    },
    {
        key: 'hub20.arrived',
        name: 'hub.shipment.search.filters.milestone.hub20.arrived',
        description: null,
    },
    {
        key: 'hub20.departed',
        name: 'hub.shipment.search.filters.milestone.hub20.departed',
        description: null,
    },
    {
        key: 'destination.arrived',
        name: 'hub.shipment.search.filters.milestone.destination.arrived',
        description:
            'hub.shipment.search.filters.milestone.destination.arrived-description',
    },
    {
        key: 'destination.unloaded',
        name: 'hub.shipment.search.filters.milestone.destination.unloaded',
        description:
            'hub.shipment.search.filters.milestone.destination.unloaded-description',
    },
    {
        key: 'destination.customs',
        name: 'hub.shipment.search.filters.milestone.destination.customs',
        description:
            'hub.shipment.search.filters.milestone.destination.customs-description',
    },
    {
        key: 'destination.documents',
        name: 'hub.shipment.search.filters.milestone.destination.documents',
        description:
            'hub.shipment.search.filters.milestone.destination.documents-description',
    },
    {
        key: 'destination.fullout',
        name: 'hub.shipment.search.filters.milestone.destination.fullout',
        description: null,
    },
    {
        key: 'destination.intransit',
        name: 'hub.shipment.search.filters.milestone.destination.intransit',
        description: null,
    },
    {
        key: 'destination.transitarrived',
        name: 'hub.shipment.search.filters.milestone.destination.transitarrived',
        description: null,
    },
    {
        key: 'destination.deliveryscheduled',
        name: 'hub.shipment.search.filters.milestone.destination.deliveryscheduled',
        description: null,
    },
    {
        key: 'destination.dispatched',
        name: 'hub.shipment.search.filters.milestone.destination.dispatched',
        description: null,
    },
    {
        key: 'destination.delivered',
        name: 'hub.shipment.search.filters.milestone.destination.delivered',
        description:
            'hub.shipment.search.filters.milestone.destination.delivered-description',
    },
    {
        key: 'destination.emptyin',
        name: 'hub.shipment.search.filters.milestone.destination.emptyin',
        description: null,
    },
    {
        key: 'destination.invoice',
        name: 'hub.shipment.search.filters.milestone.destination.invoice',
        description: null,
    },
];
