import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'simplishipObjectToDate',
})
export class SimplishipObjectToDatePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        if (value && value !== null && typeof value === 'object') {
            return new Date(value.Year, value.Month - 1, value.Day);
        }
        return value;
    }
}
