import { QuotationDetailPageComponent } from './quotation-detail-page/quotation-detail-page.component';
import { QuotationsIndexPageComponent } from './quotations-index-page/quotations-index-page.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pages: any[] = [
    QuotationsIndexPageComponent,
    QuotationDetailPageComponent,
];

export { QuotationsIndexPageComponent, QuotationDetailPageComponent };
