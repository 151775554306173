<ul class="list-group shadow-sm">
    <ng-container *ngIf="(loading$ | async) === false; else loading">
        <ng-container *ngIf="invoices$ | async; let invoices">
            <li *ngFor="let invoice of invoices" class="list-group-item p-lg-4">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <div class="font-weight-bold">
                            {{ invoice.invoice_number }}
                        </div>
                    </div>
                    <div class="font-weight-bold">
                        {{ invoice.total_amount | currency: '':''  }}
                    </div>
                </div>
                <div
                    *ngFor="let invoiceLine of invoice.invoice_lines"
                    class="row my-2"
                >
                    <div class="col-6">
                        {{ invoiceLine.description }}
                    </div>
                    <div class="col-2 text-right">
                        {{ invoiceLine.quantity }}x
                        {{ invoiceLine.price | currency: invoiceLine.currency }}
                    </div>
                    <div class="col-2 text-right">
                        {{
                            invoiceLine.total_amount
                                | currency: invoiceLine.currency
                        }}
                    </div>
                    <div class="col-2 text-right">
                        {{
                            invoiceLine.total_amount_invoice
                                | currency: '':''
                        }}
                    </div>
                </div>
            </li>
            <li
                *ngIf="invoices.length === 0"
                class="list-group-item text-muted py-5 border-bottom"
            >
                {{'portal.shipment.page.related-invoices.no-charges-yet' | translate}}
            </li>
        </ng-container>
    </ng-container>
</ul>

<ng-template #loading>
    <li class="list-group-item text-center text-muted">
        <qwyk-loading-indicator
            componentClass="text-muted m-4"
            size="sm"
            text="{{ 'common.loading' | translate }} {{ 'portal.warehouse-receipts.page.summary.section_titles.charges' | translate | lowercase }}..."
        >
        </qwyk-loading-indicator>
    </li>
</ng-template>
