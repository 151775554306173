import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';

import {
    switchMap,
    map,
    distinctUntilChanged,
    debounceTime,
    tap,
    catchError,
    take,
} from 'rxjs/operators';
import * as saveAs from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, BehaviorSubject, concat, of } from 'rxjs';

import { Animations } from '@qwyk/ui';
import { Portals, UserSummary } from '@qwyk/models';
import { SharingModalComponent } from '@qwyk/ui-standalone';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';

import { QuotationsService } from '../../services/quotations.service';

@Component({
    selector: 'qwyk-quotation-view',
    templateUrl: './quotation-view.component.html',
    styleUrls: ['./quotation-view.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class QuotationViewComponent implements OnDestroy {
    private actionsSubscription: Subscription;
    private _sortOrder = '-total_amount';
    private sortOrderSubject: BehaviorSubject<string> = new BehaviorSubject(
        this._sortOrder
    );

    public selectedQuotation$ = this.quotationsFacade.selectedQuotations$;
    public offers$ = this.sortOrderSubject.pipe(
        switchMap(order =>
            this.selectedQuotation$.pipe(
                map(quote => this.setQuotationSortOrder(quote, order))
            )
        )
    );
    public isDownloading = false;
    public resultsPage = 1;
    public resultsPageSize = 10;
    selectedQuotationSubscription: Subscription;
    shareQuotationSubscription: Subscription;
    unshareQuotationSubscription: Subscription;
    cancelSharingQuotationSubscription: Subscription;

    constructor(
        private quotationsFacade: QuotationsFacade,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private service: QuotationsService
    ) {
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.quotationsFacade.selectQuotation(params.id);
        });
    }

    ngOnDestroy() {
        this.actionsSubscription.unsubscribe();

        for (const subscr of [
            this.selectedQuotationSubscription,
            this.shareQuotationSubscription,
            this.unshareQuotationSubscription,
            this.cancelSharingQuotationSubscription,
        ]) {
            if (subscr) {
                subscr.unsubscribe();
            }
        }
    }

    public set sortOrder(sortOrder: string) {
        this._sortOrder = sortOrder;
        this.sortOrderSubject.next(sortOrder);
    }

    public get sortOrder(): string {
        return this._sortOrder;
    }

    private setQuotationSortOrder(
        quotation: Portals.QuotationEntity,
        sortOrder: string
    ): Portals.QuotationOffer[] {
        const offers = Object.assign([], quotation.results);
        offers.sort((a, b) => {
            const direction = sortOrder.substr(0, 1);
            const key = sortOrder.substr(1, sortOrder.length - 1);
            const one = direction === '-' ? a[key] : b[key];
            const two = direction === '-' ? b[key] : a[key];
            if (two == null) {
                return -1;
            }

            if (one == null) {
                return 1;
            }

            return one > two ? 1 : -1;
        });

        return offers;
    }

    public download(quotation: Portals.QuotationEntity) {
        this.isDownloading = true;
        const subscription = this.service
            .downloadQuotation(quotation.id)
            .pipe(take(1))
            .subscribe(
                response => {
                    subscription.unsubscribe();
                    saveAs(response, `quotation-${quotation.id}.pdf`);
                    this.isDownloading = false;
                },
                () => {
                    subscription.unsubscribe();
                    this.isDownloading = false;
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }

    public share() {
        const modalRef = this.modal.open(SharingModalComponent, {
            centered: true,
        });

        const component = modalRef.componentInstance;

        component.users$ = concat(
            of([]),
            component.userInput$.pipe(
                distinctUntilChanged(),
                debounceTime(400),
                tap(() => (component.usersLoading = true)),
                switchMap(term => {
                    return this.service.getUsersForSharing(term as string).pipe(
                        catchError(() => of([])),
                        tap(() => (component.usersLoading = false))
                    );
                })
            )
        );

        if (this.selectedQuotationSubscription) {
            this.selectedQuotationSubscription.unsubscribe();
        }

        this.selectedQuotationSubscription = this.selectedQuotation$.subscribe(
            quotation => {
                for (const subscr of [
                    this.shareQuotationSubscription,
                    this.unshareQuotationSubscription,
                    this.cancelSharingQuotationSubscription,
                ]) {
                    if (subscr) {
                        subscr.unsubscribe();
                    }
                }

                component.owner = quotation.owner;
                component.sharedUsers = quotation.shared_users;
                this.cancelSharingQuotationSubscription =
                    component.cancel.subscribe(() => {
                        modalRef.dismiss();
                    });

                this.shareQuotationSubscription = component.share
                    .pipe(
                        take(1),
                        tap(() => {
                            component.busy = true;
                        }),
                        switchMap(
                            (data: {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                users: any[];
                                notify: boolean;
                                message: string;
                            }) =>
                                this.service.shareQuotation(quotation.id, data)
                        )
                    )
                    .subscribe(
                        data => {
                            this.quotationsFacade.quotationUpdated(data);
                            component.busy = false;
                            modalRef.close();
                        },
                        () => {
                            component.busy = false;
                            // HACK: have a proper handling of this error:
                            alert(
                                'Something went wrong, try again or contact support if the problem persists.'
                            ); // FIXME:
                        }
                    );

                this.unshareQuotationSubscription = component.unshare
                    .pipe(
                        tap(() => {
                            component.busy = true;
                        }),
                        switchMap((data: { user: UserSummary }) =>
                            this.service.unShareQuotation(
                                quotation.id,
                                data.user
                            )
                        )
                    )
                    .subscribe(
                        data => {
                            this.quotationsFacade.quotationUpdated(data);
                            component.busy = false;
                        },
                        () => {
                            component.busy = false;
                            // HACK:
                            alert(
                                'Something went wrong, try again or contact support if the problem persists.'
                            ); // FIXME:
                        }
                    );
            }
        );
    }
}
